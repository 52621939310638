import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
    // Input Variables
    const [sqFtCanopy, setSqFtCanopy] = useState(
        localStorage.getItem('sqFtCanopy')
            ? JSON.parse(localStorage.getItem('sqFtCanopy'))
            : ''
    );
    const [gSqFtHarvest, setGSqFtHarvest] = useState(
        localStorage.getItem('gSqFtHarvest')
            ? JSON.parse(localStorage.getItem('gSqFtHarvest'))
            : ''
    );
    const [lbsSqFtHarvest, setLbsSqFtHarvest] = useState(
        localStorage.getItem('lbsSqFtHarvest')
            ? JSON.parse(localStorage.getItem('lbsSqFtHarvest'))
            : ''
    );
    const [isGrams, setIsGrams] = useState(true);
    const [wholesalePrice, setWholesalePrice] = useState(
        localStorage.getItem('wholesalePrice')
            ? JSON.parse(localStorage.getItem('wholesalePrice'))
            : ''
    );
    const [costToProduce, setCostToProduce] = useState(
        localStorage.getItem('costToProduce')
            ? JSON.parse(localStorage.getItem('costToProduce'))
            : ''
    );
    const [harvestsPerYr, setHarvestsPerYr] = useState(
        localStorage.getItem('harvestsPerYr')
            ? JSON.parse(localStorage.getItem('harvestsPerYr'))
            : ''
    );
    const [propRooms, setPropRooms] = useState(
        localStorage.getItem('propRooms')
            ? JSON.parse(localStorage.getItem('propRooms'))
            : ''
    );
    const [vegRooms, setVegRooms] = useState(
        localStorage.getItem('vegRooms')
            ? JSON.parse(localStorage.getItem('vegRooms'))
            : ''
    );
    const [flowerRooms, setFlowerRooms] = useState(
        localStorage.getItem('flowerRooms')
            ? JSON.parse(localStorage.getItem('flowerRooms'))
            : ''
    );
    const [dryingRooms, setDryingRooms] = useState(
        localStorage.getItem('dryingRooms')
            ? JSON.parse(localStorage.getItem('dryingRooms'))
            : ''
    );
    // Output Variables
    const [softwareFee, setSoftwareFee] = useState('');
    const [proSoftwareFee, setProSoftwareFee] = useState(
        localStorage.getItem('proSoftwareFee')
            ? JSON.parse(localStorage.getItem('proSoftwareFee'))
            : ''
    );
    const [subscriptionCost, setSubscriptionCost] = useState('');
    const [eliteSubscriptionCost, setEliteSubscriptionCost] = useState(
        localStorage.getItem('eliteSubscriptionCost')
            ? JSON.parse(localStorage.getItem('eliteSubscriptionCost'))
            : ''
    );
    const [unlimitedSubscriptionCost, setUnlimitedSubscriptionCost] = useState(
        localStorage.getItem('unlimitedSubscriptionCost')
            ? JSON.parse(localStorage.getItem('unlimitedSubscriptionCost'))
            : ''
    );
    const [goSubstrateCost, setGoSubstrateCost] = useState('');
    const [goSubstrateCount, setGoSubstrateCount] = useState(
        localStorage.getItem('goSubstrateCount')
            ? JSON.parse(localStorage.getItem('goSubstrateCount'))
            : ''
    );
    const [proSubstrateCost, setProSubstrateCost] = useState('');
    const [proSubstrateCount, setProSubstrateCount] = useState(
        localStorage.getItem('proSubstrateCount')
            ? JSON.parse(localStorage.getItem('proSubstrateCount'))
            : ''
    );
    const [eliteSubstrateCost, setEliteSubstrateCost] = useState('');
    const [eliteSubstrateCount, setEliteSubstrateCount] = useState(
        localStorage.getItem('eliteSubstrateCount')
            ? JSON.parse(localStorage.getItem('eliteSubstrateCount'))
            : ''
    );
    const [unlimitedSubstrateCost, setUnlimitedSubstrateCost] = useState('');
    const [unlimitedSubstrateCount, setUnlimitedSubstrateCount] = useState(
        localStorage.getItem('unlimitedSubstrateCount')
            ? JSON.parse(localStorage.getItem('unlimitedSubstrateCount'))
            : ''
    );
    const [goClimateCost, setGoClimateCost] = useState('');
    const [goClimateCount, setGoClimateCount] = useState(
        localStorage.getItem('goClimateCount')
            ? JSON.parse(localStorage.getItem('goClimateCount'))
            : ''
    );
    const [proClimateCost, setProClimateCost] = useState('');
    const [proClimateCount, setProClimateCount] = useState(
        localStorage.getItem('proClimateCount')
            ? JSON.parse(localStorage.getItem('proClimateCount'))
            : ''
    );
    const [eliteClimateCost, setEliteClimateCost] = useState('');
    const [eliteClimateCount, setEliteClimateCount] = useState(
        localStorage.getItem('eliteClimateCount')
            ? JSON.parse(localStorage.getItem('eliteClimateCount'))
            : ''
    );
    const [unlimitedClimateCost, setUnlimitedClimateCost] = useState('');
    const [unlimitedClimateCount, setUnlimitedClimateCount] = useState(
        localStorage.getItem('unlimitedClimateCount')
            ? JSON.parse(localStorage.getItem('unlimitedClimateCount'))
            : ''
    );
    const [goRepeaterCount, setGoRepeaterCount] = useState(
        localStorage.getItem('goRepeaterCount')
            ? JSON.parse(localStorage.getItem('goRepeaterCount'))
            : ''
    );
    const [goRepeaterCost, setGoRepeaterCost] = useState('');
    const [proRepeaterCount, setProRepeaterCount] = useState(
        localStorage.getItem('proRepeaterCount')
            ? JSON.parse(localStorage.getItem('proRepeaterCount'))
            : ''
    );
    const [proRepeaterCost, setProRepeaterCost] = useState('');
    const [eliteRepeaterCount, setEliteRepeaterCount] = useState(
        localStorage.getItem('eliteRepeaterCount')
            ? JSON.parse(localStorage.getItem('eliteRepeaterCount'))
            : ''
    );
    const [eliteRepeaterCost, setEliteRepeaterCost] = useState('');
    const [unlimitedRepeaterCount, setUnlimitedRepeaterCount] = useState(
        localStorage.getItem('unlimitedRepeaterCount')
            ? JSON.parse(localStorage.getItem('unlimitedRepeaterCount'))
            : ''
    );
    const [unlimitedRepeaterCost, setUnlimitedRepeaterCost] = useState('');
    const [goGatewayCount, setGoGatewayCount] = useState(
        localStorage.getItem('goGatewayCount')
            ? JSON.parse(localStorage.getItem('goGatewayCount'))
            : ''
    );
    const [goGatewayCost, setGoGatewayCost] = useState('');
    const [proGatewayCount, setProGatewayCount] = useState(
        localStorage.getItem('proGatewayCount')
            ? JSON.parse(localStorage.getItem('proGatewayCount'))
            : ''
    );
    const [proGatewayCost, setProGatewayCost] = useState('');
    const [eliteGatewayCount, setEliteGatewayCount] = useState(
        localStorage.getItem('eliteGatewayCount')
            ? JSON.parse(localStorage.getItem('eliteGatewayCount'))
            : ''
    );
    const [eliteGatewayCost, setEliteGatewayCost] = useState('');
    const [unlimitedGatewayCount, setUnlimitedGatewayCount] = useState(
        localStorage.getItem('unlimitedGatewayCount')
            ? JSON.parse(localStorage.getItem('unlimitedGatewayCount'))
            : ''
    );
    const [unlimitedGatewayCost, setUnlimitedGatewayCost] = useState('');
    const [goTotalHardwareCost, setGoTotalHardwareCost] = useState(
        localStorage.getItem('goTotalHardwareCost')
            ? JSON.parse(localStorage.getItem('goTotalHardwareCost'))
            : ''
    );
    const [proTotalHardwareCost, setProTotalHardwareCost] = useState(
        localStorage.getItem('proTotalHardwareCost')
            ? JSON.parse(localStorage.getItem('proTotalHardwareCost'))
            : ''
    );
    const [eliteTotalHardwareCost, setEliteTotalHardwareCost] = useState(
        localStorage.getItem('eliteTotalHardwareCost')
            ? JSON.parse(localStorage.getItem('eliteTotalHardwareCost'))
            : ''
    );
    const [unlimitedTotalHardwareCost, setUnlimitedTotalHardwareCost] =
        useState(
            localStorage.getItem('unlimitedTotalHardwareCost')
                ? JSON.parse(localStorage.getItem('unlimitedTotalHardwareCost'))
                : ''
        );
    // Revenue Variables
    const [baseResults, setBaseResults] = useState(
        localStorage.getItem('baseResults')
            ? JSON.parse(localStorage.getItem('baseResults'))
            : {
                  lbsProduced: '',
                  modelRevenue: '',
                  costToProduce: '',
                  profit: '',
              }
    );
    const [goRevenueIncrease, setGoRevenueIncrease] = useState(
        localStorage.getItem('goRevenueIncrease')
            ? JSON.parse(localStorage.getItem('goRevenueIncrease'))
            : ''
    );
    const [goLbsCultivationIncrease, setGoLbsCultivationIncrease] = useState(
        localStorage.getItem('goLbsCultivationIncrease')
            ? JSON.parse(localStorage.getItem('goLbsCultivationIncrease'))
            : ''
    );
    const [proRevenueIncrease, setProRevenueIncrease] = useState(
        localStorage.getItem('proRevenueIncrease')
            ? JSON.parse(localStorage.getItem('proRevenueIncrease'))
            : ''
    );
    const [proLbsCultivationIncrease, setProLbsCultivationIncrease] = useState(
        localStorage.getItem('proLbsCultivationIncrease')
            ? JSON.parse(localStorage.getItem('proLbsCultivationIncrease'))
            : ''
    );
    const [eliteRevenueIncrease, setEliteRevenueIncrease] = useState(
        localStorage.getItem('eliteRevenueIncrease')
            ? JSON.parse(localStorage.getItem('eliteRevenueIncrease'))
            : ''
    );
    const [eliteLbsCultivationIncrease, setEliteLbsCultivationIncrease] =
        useState(
            localStorage.getItem('eliteLbsCultivationIncrease')
                ? JSON.parse(
                      localStorage.getItem('eliteLbsCultivationIncrease')
                  )
                : ''
        );
    const [unlimitedRevenueIncrease, setUnlimitedRevenueIncrease] = useState(
        localStorage.getItem('unlimitedRevenueIncrease')
            ? JSON.parse(localStorage.getItem('unlimitedRevenueIncrease'))
            : ''
    );
    const [
        unlimitedLbsCultivationIncrease,
        setUnlimitedLbsCultivationIncrease,
    ] = useState(
        localStorage.getItem('unlimitedLbsCultivationIncrease')
            ? JSON.parse(
                  localStorage.getItem('unlimitedLbsCultivationIncrease')
              )
            : ''
    );
    const [dryingRevenueIncrease, setDryingRevenueIncrease] = useState(
        localStorage.getItem('dryingRevenueIncrease')
            ? JSON.parse(localStorage.getItem('dryingRevenueIncrease'))
            : ''
    );

    // Unused Input Variables
    const [substrateSensor, setSubstrateSensor] = useState(1);
    const [climateSensor, setClimateSensor] = useState(1);
    const [repeater, setRepeater] = useState(0);
    const [aqualab, setAqualab] = useState(false);
    const [irrigZones, setIrrigZones] = useState(0);
    // Unused Output Variables
    const aqualabCost = 12000;
    // const [totalCost, setTotalCost] = useState(0);

    // Calculations
    function computeSoftwareFee() {
        const swUnlimitedFee = 0;
        setSoftwareFee(swUnlimitedFee);
        const swEliteFee = 0;
        setSoftwareFee(swEliteFee);
        const swProFee = proSubstrateCount * 150 + proClimateCount * 150;
        setProSoftwareFee(swProFee);
        const swGoFee = 0;
        setSoftwareFee(swGoFee);
    }
    function computeSubscriptionCost() {
        const subsUnlimitedCost = sqFtCanopy * 1.55;
        setUnlimitedSubscriptionCost(subsUnlimitedCost);
        const subsEliteCost = sqFtCanopy * 1.37;
        setEliteSubscriptionCost(subsEliteCost);
        const subsProCost = sqFtCanopy * 0;
        setSubscriptionCost(subsProCost);
        const subsGoCost = sqFtCanopy * 0;
        setSubscriptionCost(subsGoCost);
    }
    function computeSubstrateCount() {
        const subUnlimitedCount = sqFtCanopy / 100;
        setUnlimitedSubstrateCount(Math.ceil(subUnlimitedCount));
        const subEliteCount = sqFtCanopy / 100;
        setEliteSubstrateCount(Math.ceil(subEliteCount));
        const subProCount = sqFtCanopy / 200;
        setProSubstrateCount(Math.ceil(subProCount));
        const subGoCount = sqFtCanopy / 200;
        setGoSubstrateCount(Math.ceil(subGoCount));
    }
    function computeSubstrateCost() {
        const subUnlimitedCost = unlimitedSubstrateCount * 414;
        setUnlimitedSubstrateCost(subUnlimitedCost);
        const subEliteCost = eliteSubstrateCount * 414;
        setEliteSubstrateCost(subEliteCost);
        const subProCost = proSubstrateCount * 538;
        setProSubstrateCost(subProCost);
        const subGoCost = goSubstrateCount * 696;
        setGoSubstrateCost(subGoCost);
    }
    function computeClimateCount() {
        const climUnlimitedCount =
            propRooms + vegRooms + dryingRooms + flowerRooms;
        setUnlimitedClimateCount(climUnlimitedCount);
        const climEliteCount = propRooms + vegRooms + dryingRooms + flowerRooms;
        setEliteClimateCount(climEliteCount);
        const climProCount = propRooms + vegRooms + dryingRooms + flowerRooms;
        setProClimateCount(climProCount);
        const climGoCount = propRooms + vegRooms + dryingRooms + flowerRooms;
        setGoClimateCount(climGoCount);
    }
    function computeClimateCost() {
        const climUnlimitedCost = unlimitedClimateCount * 414;
        setUnlimitedClimateCost(climUnlimitedCost);
        const climEliteCost = eliteClimateCount * 414;
        setEliteClimateCost(climEliteCost);
        const climProCost = proClimateCount * 538;
        setProClimateCost(climProCost);
        const climGoCost = goClimateCount * 696;
        setGoClimateCost(climGoCost);
    }
    function computeRepeaterCount() {
        const repUnlimitedCount = flowerRooms;
        setUnlimitedRepeaterCount(repUnlimitedCount);
        const repEliteCount = flowerRooms;
        setEliteRepeaterCount(repEliteCount);
        const repProCount = flowerRooms;
        setProRepeaterCount(repProCount);
        const repGoCount = flowerRooms;
        setGoRepeaterCount(repGoCount);
    }
    function computeRepeaterCost() {
        const repUnlimitedCost = unlimitedRepeaterCount * 254;
        setUnlimitedRepeaterCost(repUnlimitedCost);
        const repEliteCost = eliteRepeaterCount * 254;
        setEliteRepeaterCost(repEliteCost);
        const repProCost = proRepeaterCount * 305;
        setProRepeaterCost(repProCost);
        const repGoCost = goRepeaterCount * 427;
        setGoRepeaterCost(repGoCost);
    }
    function computeGatewayCount() {
        const gateUnlimitedCount =
            (unlimitedSubstrateCount +
                unlimitedClimateCount +
                unlimitedRepeaterCount) /
            150;
        setUnlimitedGatewayCount(Math.ceil(gateUnlimitedCount));
        const gateEliteCount =
            (eliteSubstrateCount + eliteClimateCount + eliteRepeaterCount) /
            150;
        setEliteGatewayCount(Math.ceil(gateEliteCount));
        const gateProCount =
            (proSubstrateCount + proClimateCount + proRepeaterCount) / 150;
        setProGatewayCount(Math.ceil(gateProCount));
        const gateGoCount =
            (goSubstrateCount + goClimateCount + goRepeaterCount) / 150;
        setGoGatewayCount(Math.ceil(gateGoCount));
    }
    function computeGatewayCost() {
        const gateUnlimitedCost = unlimitedGatewayCount * 414;
        setUnlimitedGatewayCost(gateUnlimitedCost);
        const gateEliteCost = eliteGatewayCount * 414;
        setEliteGatewayCost(gateEliteCost);
        const gateProCost = proGatewayCount * 538;
        setProGatewayCost(gateProCost);
        const gateGoCost = goGatewayCount * 696;
        setGoGatewayCost(gateGoCost);
    }
    function computeTotalHardwareCost() {
        const goTotal =
            goSubstrateCost + goClimateCost + goRepeaterCost + goGatewayCost;
        setGoTotalHardwareCost(goTotal);
        const proTotal =
            proSubstrateCost +
            proClimateCost +
            proRepeaterCost +
            proGatewayCost;
        setProTotalHardwareCost(proTotal);
        const eliteTotal =
            eliteSubstrateCost +
            eliteClimateCost +
            eliteRepeaterCost +
            eliteGatewayCost;
        setEliteTotalHardwareCost(eliteTotal);
        const unlimitedTotal =
            unlimitedSubstrateCost +
            unlimitedClimateCost +
            unlimitedRepeaterCost +
            unlimitedGatewayCost;
        setUnlimitedTotalHardwareCost(unlimitedTotal);
    }
    function calculateBaseResults() {
        let lbs = (harvestsPerYr / 12) * ((sqFtCanopy * gSqFtHarvest) / 453);
        const lbsProduced = Math.round(lbs);
        let model =
            (harvestsPerYr / 12) *
            (sqFtCanopy * gSqFtHarvest) *
            (wholesalePrice / 453);
        const modelRevenue = Math.round(model);
        const cost = Math.round(costToProduce);
        let prof = modelRevenue - lbsProduced * cost;
        const profit = Math.round(prof);

        setBaseResults({
            lbsProduced,
            modelRevenue,
            costToProduce: cost,
            profit,
        });
    }
    function computeRevenueIncrease() {
        const unlimitedRev = ((3 - 2) / 12) * 0.15 * baseResults.modelRevenue;
        setUnlimitedRevenueIncrease(unlimitedRev);
        const eliteRev = ((4 - 3) / 12) * 0.1 * baseResults.modelRevenue;
        setEliteRevenueIncrease(eliteRev);
        const proRev = ((5 - 4) / 12) * 0.05 * baseResults.modelRevenue;
        setProRevenueIncrease(proRev);
        const goRev = ((6 - 5) / 12) * 0.02 * baseResults.modelRevenue;
        setGoRevenueIncrease(goRev);
    }
    function computeLbsCultivationIncrease() {
        const unlimitedLbs = unlimitedRevenueIncrease / wholesalePrice;
        setUnlimitedLbsCultivationIncrease(unlimitedLbs);
        const eliteLbs = eliteRevenueIncrease / wholesalePrice;
        setEliteLbsCultivationIncrease(eliteLbs);
        const proLbs = proRevenueIncrease / wholesalePrice;
        setProLbsCultivationIncrease(proLbs);
        const goLbs = goRevenueIncrease / wholesalePrice;
        setGoLbsCultivationIncrease(goLbs);
    }
    function computeDryingIntelligence() {
        const dryingRev = 0.01 * baseResults.modelRevenue;
        setDryingRevenueIncrease(dryingRev);
    }

    // Unused Calculations
    // function computeTotalCost() {
    //     const total = softwareFee + subscriptionCost + totalHardwareCost;
    //     setTotalCost(total);
    // }

    return (
        <GlobalContext.Provider
            value={{
                sqFtCanopy,
                irrigZones,
                gSqFtHarvest,
                wholesalePrice,
                costToProduce,
                harvestsPerYr,
                vegRooms,
                setSqFtCanopy,
                setIrrigZones,
                setGSqFtHarvest,
                dryingRevenueIncrease,
                setWholesalePrice,
                setCostToProduce,
                setHarvestsPerYr,
                setVegRooms,
                subscriptionCost,
                computeSubscriptionCost,
                computeSubstrateCount,
                computeClimateCount,
                computeTotalHardwareCost,
                computeSubstrateCost,
                computeClimateCost,
                aqualabCost,
                computeRepeaterCost,
                computeRepeaterCount,
                baseResults,
                goRevenueIncrease,
                computeDryingIntelligence,
                proRevenueIncrease,
                eliteRevenueIncrease,
                unlimitedRevenueIncrease,
                computeRevenueIncrease,
                computeLbsCultivationIncrease,
                calculateBaseResults,
                goLbsCultivationIncrease,
                proLbsCultivationIncrease,
                eliteLbsCultivationIncrease,
                unlimitedLbsCultivationIncrease,
                propRooms,
                dryingRooms,
                flowerRooms,
                setPropRooms,
                setDryingRooms,
                setFlowerRooms,
                computeGatewayCount,
                computeGatewayCost,
                substrateSensor,
                setSubstrateSensor,
                climateSensor,
                setClimateSensor,
                repeater,
                setRepeater,
                computeSoftwareFee,
                softwareFee,
                aqualab,
                setAqualab,
                lbsSqFtHarvest,
                setLbsSqFtHarvest,
                isGrams,
                setIsGrams,
                proSoftwareFee,
                eliteSubscriptionCost,
                unlimitedSubscriptionCost,
                goSubstrateCount,
                proSubstrateCount,
                eliteSubstrateCount,
                unlimitedSubstrateCount,
                goClimateCount,
                proClimateCount,
                eliteClimateCount,
                unlimitedClimateCount,
                goRepeaterCount,
                proRepeaterCount,
                eliteRepeaterCount,
                unlimitedRepeaterCount,
                goGatewayCount,
                proGatewayCount,
                eliteGatewayCount,
                unlimitedGatewayCount,
                goTotalHardwareCost,
                proTotalHardwareCost,
                eliteTotalHardwareCost,
                unlimitedTotalHardwareCost,
                goSubstrateCost,
                proSubstrateCost,
                eliteSubstrateCost,
                unlimitedSubstrateCost,
                goClimateCost,
                proClimateCost,
                eliteClimateCost,
                unlimitedClimateCost,
                goRepeaterCost,
                proRepeaterCost,
                eliteRepeaterCost,
                unlimitedRepeaterCost,
                goGatewayCost,
                proGatewayCost,
                eliteGatewayCost,
                unlimitedGatewayCost,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalContextProvider;
