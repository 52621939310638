import React, { useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {},
    header: {
        position: 'relative',
        marginBottom: '2rem',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '1rem',
        },
    },
    aroyaLogo: {},
    main: {},
    paper: {
        margin: '0 0 0 6rem',
        maxWidth: '400px',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            margin: '0 0 0 3rem',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '2rem 0 0 0',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '1rem 0 0 0',
        },
    },
    modalTitle: {
        marginBottom: '30px',
        [theme.breakpoints.down('md')]: {
            fontSize: '2.6rem',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '2.4rem',
        },
    },
    modalBody: {
        marginBottom: '25px',
        fontSize: '1.2rem',
        maxWidth: '350px',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginBottom: '15px',
        },
    },
    modalBodyLast: {
        marginBottom: '25px',
        paddingTop: '25px',
        fontSize: '1.2rem',
        maxWidth: '350px',
        position: 'relative',
        '&::before': {
            content: "''",
            height: '1px',
            width: '100%',
            borderTop: '1px solid #000',
            position: 'absolute',
            top: '0',
            left: '0',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginBottom: '15px',
            paddingTop: '15px',
        },
    },
}));

const Homepage = () => {
    const classes = useStyles();
    let navigate = useNavigate();
    const sqFtCanopyData = JSON.parse(localStorage.getItem('sqFtCanopy')) || '';

    useEffect(() => {
        if (sqFtCanopyData) {
            navigate('/your-facility?unlock=true');
        }
    }, [navigate, sqFtCanopyData]);

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <svg
                    alt="Aroya"
                    width="75"
                    height="75"
                    viewBox="0 0 276 237"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={classes.aroyaLogo}
                >
                    <path
                        d="M276 18.7256C276 28.9116 268.078 36.8341 257.891 36.8341C247.705 36.8341 239.5 28.9116 239.5 18.7256C239.5 8.82249 247.705 0.900002 257.891 0.900002C268.078 1.18295 276 9.10543 276 18.7256ZM244.027 18.7256C244.027 26.6481 249.969 32.8729 257.891 32.8729C265.814 32.8729 271.473 26.6481 271.473 18.7256C271.473 10.8031 265.814 4.29535 257.891 4.29535C249.969 4.5783 244.027 11.0861 244.027 18.7256ZM255.062 28.0628H250.818V10.2372C252.516 9.95427 254.779 9.67132 257.609 9.67132C261.004 9.67132 262.419 10.2372 263.833 11.0861C264.682 11.9349 265.531 13.3496 265.531 15.0473C265.531 17.0279 264.116 18.4426 261.853 19.2915V19.5744C263.55 20.1403 264.682 21.5551 264.965 23.8186C265.531 26.6481 265.814 27.4969 266.38 28.3458H261.853C261.287 27.7799 261.004 26.0822 260.438 24.1016C260.155 22.1209 259.023 21.2721 256.76 21.2721H254.779V28.0628H255.062ZM255.062 18.1597H257.043C259.306 18.1597 261.287 17.3109 261.287 15.6132C261.287 13.9155 260.155 12.7837 257.609 12.7837C256.477 12.7837 255.628 12.7837 255.345 13.0667V18.1597H255.062Z"
                        fill="black"
                    ></path>
                    <path
                        d="M87.4 104.7H77.9V116.6H87.5C91.6 116.6 93.9 114.5 93.9 110.7C94 107.5 91.5 104.7 87.4 104.7Z"
                        fill="black"
                    ></path>
                    <path
                        d="M42.4 124H53.7L48 107.2L42.4 124Z"
                        fill="black"
                    ></path>
                    <path
                        d="M121.8 104.2C119.5 104.2 117.1 105.2 115.5 106.8C112.8 109.7 112.6 114.6 112.6 118.2C112.6 121.8 112.7 126.7 115.5 129.6C117.1 131.3 119.5 132.2 121.8 132.2C124.1 132.2 126.5 131.2 128.1 129.6C130.8 126.7 131 121.9 131 118.2C131 114.6 130.9 109.6 128.1 106.8C126.5 105.2 124.1 104.2 121.8 104.2Z"
                        fill="black"
                    ></path>
                    <path
                        d="M182.6 124H193.9L188.2 107.2L182.6 124Z"
                        fill="black"
                    ></path>
                    <path
                        d="M0 0V236.5H236.5V0H0ZM58.5 138.1L55.9 130.5H40.1L37.5 138.1H30.3L44.2 98.4H52.1L66 138.1H58.5ZM101.2 138.1H93.4L86.8 123.1H78V138.1H70.7V98.4H87.4C96.9 98.4 101.2 104.3 101.2 110.7C101.2 116 98.3 120.2 93.9 121.8L101.2 138.1ZM133.2 134.3C130.2 137.3 126 138.8 121.8 138.8C117.6 138.8 113.4 137.3 110.4 134.3C106.1 130 105.4 124.7 105.4 118.2C105.4 111.7 106.1 106.4 110.4 102.1C113.4 99.1 117.6 97.6 121.8 97.6C126.1 97.6 130.2 99.1 133.2 102.1C137.5 106.4 138.2 111.7 138.2 118.2C138.2 124.8 137.5 130 133.2 134.3ZM159.7 138.1H152.4V122.9L138.6 98.4H146.4L156.2 115.5L166 98.4H173.5L159.6 122.9V138.1H159.7ZM198.7 138.1L196.1 130.5H180.3L177.7 138.1H170.4L184.3 98.4H192.2L206.1 138.1H198.7Z"
                        fill="black"
                    ></path>
                </svg>
            </header>
            <main className={classes.main}>
                <section>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Typography
                                variant="h3"
                                component="h1"
                                className={classes.modalTitle}
                            >
                                We don't hide prices.
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.modalBody}
                            >
                                Fill out this form, and we’ll show you how AROYA
                                can work for you.
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.modalBody}
                            >
                                An email will be sent to your inbox to verify
                                you, with a link to start your facility's ROI
                                calculation.
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.modalBodyLast}
                            >
                                Our clients often recoup their initial
                                investment after one harvest.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <div className={classes.paper}>
                                <iframe
                                    title="quote-form"
                                    loading="lazy"
                                    data-src="https://go.meter.group/l/304411/2022-04-22/23fgph?fieldLabelBg=ffffff"
                                    width="100%"
                                    height="750px"
                                    type="text/html"
                                    frameBorder="0"
                                    allowtransparency="true"
                                    rel="dns-prefetch"
                                    src="https://go.meter.group/l/304411/2022-04-22/23fgph?fieldLabelBg=ffffff&amp;Special_Tracking=AROYA%20-%20Home"
                                ></iframe>
                            </div>
                        </Grid>
                    </Grid>
                </section>
            </main>
        </div>
    );
};

export default Homepage;
