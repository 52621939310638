import React, { useContext, useEffect, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
    makeStyles,
    Typography,
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
} from '@material-ui/core';
import { GlobalContext } from '../contexts/GlobalContext';
import arrowThin from '../images/arrow-thin.svg';
import postscribe from 'postscribe';

const useStyles = makeStyles((theme) => ({
    root: {},
    header: {
        marginBottom: '2rem',
        borderBottom: '2px solid #000',
    },
    aroyaLogo: {},
    headerContent: {
        padding: '2rem 0 1.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        [theme.breakpoints.down('lg')]: {
            padding: '1rem 0 1.5rem',
        },
    },
    headerContentRight: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        minWidth: 'fit-content',

        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    headerProgress: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginRight: '4.5rem',
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
    headerProgressLaptop: {
        display: 'none',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginBottom: '2rem',
        marginLeft: 'auto',
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    headerTitle: {
        fontWeight: 700,
        marginRight: '4.5rem',
        [theme.breakpoints.down('xs')]: {
            marginRight: '0',
            fontSize: '2.5rem',
        },
    },
    progressText: {
        color: 'rgb(191, 191, 191)',
        margin: '0 1rem',
        [theme.breakpoints.down('xs')]: {
            margin: '0 0.7rem',
        },
    },
    progressTextActive: {
        color: '#000',
        margin: '0 1rem',
        [theme.breakpoints.down('xs')]: {
            margin: '0 0.7rem',
        },
    },
    progressTextDisabled: {
        color: 'rgb(191, 191, 191)',
        margin: '0 1rem',
        cursor: 'not-allowed',
        [theme.breakpoints.down('xs')]: {
            margin: '0 0.7rem',
        },
    },
    headerStep: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    stepText: {
        fontWeight: 700,
        marginRight: '1.5rem',
    },
    arrowRight: {
        width: '50px',
    },
    main: {
        padding: '4rem 1rem 3rem',
        borderBottom: '2px solid #000',
        [theme.breakpoints.down('sm')]: {
            padding: '2rem 1rem 3rem',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 1rem 2rem',
        },
    },
    textTitle: {
        textTransform: 'uppercase',
        marginBottom: '2rem',
        '&::after': {
            content: '""',
            display: 'block',
            float: 'right',
            width: '30px',
            height: '35px',
            background: `url(${arrowThin}) no-repeat center`,
            backgroundSize: '100% 100%',
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem',
            '&::after': {
                content: '""',
                display: 'block',
                float: 'right',
                width: '30px',
                height: '40px',
                background: `url(${arrowThin}) no-repeat center`,
                backgroundSize: '100% 100%',
            },
        },
    },
    textBody: {
        color: '#6a6a6a',
    },
    inputFieldContainer: {
        '&:nth-child(n+3)': {
            marginTop: '10px',
        },
    },
    hiddenInputField: {
        display: 'none',
    },
    roomsFields: {
        marginTop: '3rem',

        [theme.breakpoints.down('sm')]: {
            marginTop: '2rem',
        },
    },
    roomsTitle: {
        fontWeight: 400,
        marginBottom: '1rem',
    },
    buttonAdornment: {
        '&:first-child': {
            marginRight: '5px',
        },
    },
    inputLinkContainer: {
        marginTop: '2rem',
        textAlign: 'right',
    },
    inputLink: {
        display: 'inline-block',
        textTransform: 'Capitalize',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    footer: {
        marginTop: '2rem',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'right',
        },
    },
    privacyTitle: {
        fontWeight: 700,
        marginBottom: '5px',
    },
    privacyLink: {
        textDecoration: 'underline',
    },
}));

const YourFacility = () => {
    const classes = useStyles();
    const {
        sqFtCanopy,
        gSqFtHarvest,
        wholesalePrice,
        harvestsPerYr,
        vegRooms,
        costToProduce,
        setCostToProduce,
        setSqFtCanopy,
        setGSqFtHarvest,
        setWholesalePrice,
        setHarvestsPerYr,
        setVegRooms,
        propRooms,
        dryingRooms,
        flowerRooms,
        setPropRooms,
        setDryingRooms,
        setFlowerRooms,
        // lbsSqFtHarvest,
        // setLbsSqFtHarvest,
        // isGrams,
        // setIsGrams,
        calculateBaseResults,
        computeRevenueIncrease,
        computeDryingIntelligence,
        computeLbsCultivationIncrease,
        goRevenueIncrease,
        proRevenueIncrease,
        eliteRevenueIncrease,
        unlimitedRevenueIncrease,
        baseResults,
        dryingRevenueIncrease,
        computeSubstrateCount,
        computeSubscriptionCost,
        computeSubstrateCost,
        computeSoftwareFee,
        computeClimateCost,
        computeClimateCount,
        computeRepeaterCount,
        computeRepeaterCost,
        computeGatewayCount,
        computeGatewayCost,
        computeTotalHardwareCost,
        goSubstrateCount,
        proSubstrateCount,
        eliteSubstrateCount,
        unlimitedSubstrateCount,
        goClimateCount,
        proClimateCount,
        eliteClimateCount,
        unlimitedClimateCount,
        goRepeaterCount,
        proRepeaterCount,
        eliteRepeaterCount,
        unlimitedRepeaterCount,
        goGatewayCount,
        proGatewayCount,
        eliteGatewayCount,
        unlimitedGatewayCount,
        proSubstrateCost,
        eliteSubstrateCost,
        unlimitedSubstrateCost,
        goClimateCost,
        proClimateCost,
        eliteClimateCost,
        unlimitedClimateCost,
        goRepeaterCost,
        proRepeaterCost,
        eliteRepeaterCost,
        unlimitedRepeaterCost,
        goGatewayCost,
        proGatewayCost,
        eliteGatewayCost,
        unlimitedGatewayCost,
        goSubstrateCost,
        goLbsCultivationIncrease,
        proLbsCultivationIncrease,
        eliteLbsCultivationIncrease,
        unlimitedLbsCultivationIncrease,
        goTotalHardwareCost,
        proTotalHardwareCost,
        eliteTotalHardwareCost,
        unlimitedTotalHardwareCost,
        proSoftwareFee,
        eliteSubscriptionCost,
        unlimitedSubscriptionCost,
    } = useContext(GlobalContext);

    const [params] = useSearchParams();
    const unlock = params.get('unlock');
    const companyRef = useRef(null);
    const emailRef = useRef(null);
    let navigate = useNavigate();
    const handleSqFtCanopyInput = (event) => {
        setSqFtCanopy(
            event.target.value === ''
                ? ''
                : event.target.value < 1
                ? 1
                : event.target.value > 1000000
                ? 1000000
                : Number(event.target.value)
        );
    };
    const handleGSqFtHarvestInput = (event) => {
        setGSqFtHarvest(
            event.target.value === ''
                ? ''
                : event.target.value < 1
                ? 1
                : event.target.value > 5000
                ? 5000
                : Number(event.target.value)
        );
    };
    // Below is for Grams to Lbs button
    // const handleLbsSqFtHarvestInput = (event) => {
    //     setLbsSqFtHarvest(
    //         event.target.value === '' ? '' : Number(event.target.value)
    //     );
    // };
    // const handleLbsSqFtHarvestBlur = () => {
    //     if (lbsSqFtHarvest < 0) {
    //         setLbsSqFtHarvest(0.001);
    //     } else if (lbsSqFtHarvest > 5000) {
    //         setLbsSqFtHarvest(5000);
    //     }
    // };
    const handleWholesalePriceInput = (event) => {
        setWholesalePrice(
            event.target.value === ''
                ? ''
                : event.target.value < 1
                ? 1
                : event.target.value > 30000
                ? 30000
                : Number(event.target.value)
        );
    };
    const handleCostToProduceInput = (event) => {
        setCostToProduce(
            event.target.value === ''
                ? ''
                : event.target.value < 1
                ? 1
                : event.target.value > 30000
                ? 30000
                : Number(event.target.value)
        );
    };
    const handleHarvestsPerYrInput = (event) => {
        setHarvestsPerYr(
            event.target.value === ''
                ? ''
                : event.target.value < 2
                ? 2
                : event.target.value > 7
                ? 7
                : Number(event.target.value)
        );
    };
    const handlePropRoomsInput = (event) => {
        setPropRooms(
            event.target.value === ''
                ? ''
                : event.target.value < 0
                ? 0
                : event.target.value > 100
                ? 100
                : Number(event.target.value)
        );
    };
    const handleVegRoomsInput = (event) => {
        setVegRooms(
            event.target.value === ''
                ? ''
                : event.target.value < 0
                ? 0
                : event.target.value > 100
                ? 100
                : Number(event.target.value)
        );
    };
    const handleFlowerRoomsInput = (event) => {
        setFlowerRooms(
            event.target.value === ''
                ? ''
                : event.target.value < 0
                ? 0
                : event.target.value > 100
                ? 100
                : Number(event.target.value)
        );
    };
    const handleDryingRoomsInput = (event) => {
        setDryingRooms(
            event.target.value === ''
                ? ''
                : event.target.value < 0
                ? 0
                : event.target.value > 100
                ? 100
                : Number(event.target.value)
        );
    };
    // async function handleFormSubmit(e) {
    //     e.preventDefault();

    //     try {
    //         await e.target.submit();
    //         await navigate('/aroya-plans?unlock=true');
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }
    // Below is for Grams Lbs Button
    // const handleGramsButton = () => {
    //     setIsGrams(true);
    // };
    // const handleLbsButton = () => {
    //     setIsGrams(false);
    // };

    // Below is for Grams Lbs Button
    // useEffect(() => {
    //     const newLbs = gSqFtHarvest / 453.59237;
    //     const formatted = +(Math.round(newLbs + 'e+5') + 'e-5');
    //     setLbsSqFtHarvest(formatted);
    // }, [gSqFtHarvest, setLbsSqFtHarvest]);
    // useEffect(() => {
    //     const newGrams = lbsSqFtHarvest * 453.59237;
    //     const formatted = +(Math.round(newGrams + 'e+2') + 'e-2');
    //     setGSqFtHarvest(formatted);
    // }, [lbsSqFtHarvest, setGSqFtHarvest]);

    useEffect(() => {
        computeSubstrateCount();
        computeSubscriptionCost();
        calculateBaseResults();
        localStorage.setItem('sqFtCanopy', JSON.stringify(sqFtCanopy));
        localStorage.setItem(
            'eliteSubscriptionCost',
            JSON.stringify(eliteSubscriptionCost)
        );
        localStorage.setItem(
            'unlimitedSubscriptionCost',
            JSON.stringify(unlimitedSubscriptionCost)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sqFtCanopy]);
    useEffect(() => {
        computeRepeaterCount();
        calculateBaseResults();
        localStorage.setItem('gSqFtHarvest', JSON.stringify(gSqFtHarvest));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gSqFtHarvest]);
    useEffect(() => {
        computeRepeaterCount();
        calculateBaseResults();
        localStorage.setItem('wholesalePrice', JSON.stringify(wholesalePrice));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wholesalePrice]);
    useEffect(() => {
        computeRepeaterCount();
        calculateBaseResults();
        localStorage.setItem('costToProduce', JSON.stringify(costToProduce));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [costToProduce]);
    useEffect(() => {
        computeRepeaterCount();
        calculateBaseResults();
        localStorage.setItem('harvestsPerYr', JSON.stringify(harvestsPerYr));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [harvestsPerYr]);
    useEffect(() => {
        computeClimateCount();
        computeRepeaterCount();
        localStorage.setItem('propRooms', JSON.stringify(propRooms));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propRooms]);
    useEffect(() => {
        computeClimateCount();
        computeRepeaterCount();
        localStorage.setItem('vegRooms', JSON.stringify(vegRooms));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vegRooms]);
    useEffect(() => {
        computeClimateCount();
        computeRepeaterCount();
        localStorage.setItem('dryingRooms', JSON.stringify(dryingRooms));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dryingRooms]);
    useEffect(() => {
        computeClimateCount();
        computeRepeaterCount();
        localStorage.setItem('flowerRooms', JSON.stringify(flowerRooms));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowerRooms]);

    useEffect(() => {
        computeSubstrateCost();
        computeSoftwareFee();
        computeGatewayCount();
        localStorage.setItem(
            'goSubstrateCount',
            JSON.stringify(goSubstrateCount)
        );
        localStorage.setItem(
            'proSubstrateCount',
            JSON.stringify(proSubstrateCount)
        );
        localStorage.setItem(
            'eliteSubstrateCount',
            JSON.stringify(eliteSubstrateCount)
        );
        localStorage.setItem(
            'unlimitedSubstrateCount',
            JSON.stringify(unlimitedSubstrateCount)
        );
        localStorage.setItem('proSoftwareFee', JSON.stringify(proSoftwareFee));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        proSubstrateCount,
        unlimitedSubstrateCount,
        eliteSubstrateCount,
        goSubstrateCount,
    ]);
    useEffect(() => {
        computeClimateCost();
        computeSoftwareFee();
        computeGatewayCount();
        localStorage.setItem('goClimateCount', JSON.stringify(goClimateCount));
        localStorage.setItem(
            'proClimateCount',
            JSON.stringify(proClimateCount)
        );
        localStorage.setItem(
            'eliteClimateCount',
            JSON.stringify(eliteClimateCount)
        );
        localStorage.setItem(
            'unlimitedClimateCount',
            JSON.stringify(unlimitedClimateCount)
        );
        localStorage.setItem('proSoftwareFee', JSON.stringify(proSoftwareFee));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        unlimitedClimateCount,
        eliteClimateCount,
        proClimateCount,
        goClimateCount,
    ]);
    useEffect(() => {
        computeRepeaterCost();
        computeGatewayCount();
        localStorage.setItem(
            'goRepeaterCount',
            JSON.stringify(goRepeaterCount)
        );
        localStorage.setItem(
            'proRepeaterCount',
            JSON.stringify(proRepeaterCount)
        );
        localStorage.setItem(
            'eliteRepeaterCount',
            JSON.stringify(eliteRepeaterCount)
        );
        localStorage.setItem(
            'unlimitedRepeaterCount',
            JSON.stringify(unlimitedRepeaterCount)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        unlimitedRepeaterCount,
        eliteRepeaterCount,
        proRepeaterCount,
        goRepeaterCount,
    ]);
    useEffect(() => {
        computeGatewayCost();
        localStorage.setItem('goGatewayCount', JSON.stringify(goGatewayCount));
        localStorage.setItem(
            'proGatewayCount',
            JSON.stringify(proGatewayCount)
        );
        localStorage.setItem(
            'eliteGatewayCount',
            JSON.stringify(eliteGatewayCount)
        );
        localStorage.setItem(
            'unlimitedGatewayCount',
            JSON.stringify(unlimitedGatewayCount)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        unlimitedGatewayCount,
        eliteGatewayCount,
        proGatewayCount,
        goGatewayCount,
    ]);
    useEffect(() => {
        computeTotalHardwareCost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        goSubstrateCost,
        goClimateCost,
        goRepeaterCost,
        goGatewayCost,
        proSubstrateCost,
        proClimateCost,
        proRepeaterCost,
        proGatewayCost,
        eliteSubstrateCost,
        eliteClimateCost,
        eliteRepeaterCost,
        eliteGatewayCost,
        unlimitedSubstrateCost,
        unlimitedClimateCost,
        unlimitedRepeaterCost,
        unlimitedGatewayCost,
    ]);

    useEffect(() => {
        computeRevenueIncrease();
        computeDryingIntelligence();
        localStorage.setItem('baseResults', JSON.stringify(baseResults));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseResults]);

    useEffect(() => {
        computeLbsCultivationIncrease();
        localStorage.setItem(
            'goRevenueIncrease',
            JSON.stringify(goRevenueIncrease)
        );
        localStorage.setItem(
            'proRevenueIncrease',
            JSON.stringify(proRevenueIncrease)
        );
        localStorage.setItem(
            'eliteRevenueIncrease',
            JSON.stringify(eliteRevenueIncrease)
        );
        localStorage.setItem(
            'unlimitedRevenueIncrease',
            JSON.stringify(unlimitedRevenueIncrease)
        );
        localStorage.setItem(
            'goLbsCultivationIncrease',
            JSON.stringify(goLbsCultivationIncrease)
        );
        localStorage.setItem(
            'proLbsCultivationIncrease',
            JSON.stringify(proLbsCultivationIncrease)
        );
        localStorage.setItem(
            'eliteLbsCultivationIncrease',
            JSON.stringify(eliteLbsCultivationIncrease)
        );
        localStorage.setItem(
            'unlimitedLbsCultivationIncrease',
            JSON.stringify(unlimitedLbsCultivationIncrease)
        );
        localStorage.setItem(
            'dryingRevenueIncrease',
            JSON.stringify(dryingRevenueIncrease)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        goRevenueIncrease,
        proRevenueIncrease,
        eliteRevenueIncrease,
        unlimitedRevenueIncrease,
    ]);
    useEffect(() => {
        localStorage.setItem(
            'goTotalHardwareCost',
            JSON.stringify(goTotalHardwareCost)
        );
        localStorage.setItem(
            'proTotalHardwareCost',
            JSON.stringify(proTotalHardwareCost)
        );
        localStorage.setItem(
            'eliteTotalHardwareCost',
            JSON.stringify(eliteTotalHardwareCost)
        );
        localStorage.setItem(
            'unlimitedTotalHardwareCost',
            JSON.stringify(unlimitedTotalHardwareCost)
        );
    }, [
        goTotalHardwareCost,
        proTotalHardwareCost,
        eliteTotalHardwareCost,
        unlimitedTotalHardwareCost,
    ]);

    useEffect(() => {
        if (companyRef && emailRef && window.navigator.onLine && unlock) {
            postscribe(
                companyRef.current,
                `<script type="text/javascript" src="https://go.meter.group/dcjs/304411/1442/dc.js"></script>`
            );
            postscribe(
                emailRef.current,
                `<script type="text/javascript" src="https://go.meter.group/dcjs/304411/1570/dc.js"></script>`
            );
        } else {
            navigate('/');
        }
    }, [navigate, unlock]);

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <svg
                    alt="Aroya"
                    width="75"
                    height="75"
                    viewBox="0 0 276 237"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={classes.aroyaLogo}
                >
                    <path
                        d="M276 18.7256C276 28.9116 268.078 36.8341 257.891 36.8341C247.705 36.8341 239.5 28.9116 239.5 18.7256C239.5 8.82249 247.705 0.900002 257.891 0.900002C268.078 1.18295 276 9.10543 276 18.7256ZM244.027 18.7256C244.027 26.6481 249.969 32.8729 257.891 32.8729C265.814 32.8729 271.473 26.6481 271.473 18.7256C271.473 10.8031 265.814 4.29535 257.891 4.29535C249.969 4.5783 244.027 11.0861 244.027 18.7256ZM255.062 28.0628H250.818V10.2372C252.516 9.95427 254.779 9.67132 257.609 9.67132C261.004 9.67132 262.419 10.2372 263.833 11.0861C264.682 11.9349 265.531 13.3496 265.531 15.0473C265.531 17.0279 264.116 18.4426 261.853 19.2915V19.5744C263.55 20.1403 264.682 21.5551 264.965 23.8186C265.531 26.6481 265.814 27.4969 266.38 28.3458H261.853C261.287 27.7799 261.004 26.0822 260.438 24.1016C260.155 22.1209 259.023 21.2721 256.76 21.2721H254.779V28.0628H255.062ZM255.062 18.1597H257.043C259.306 18.1597 261.287 17.3109 261.287 15.6132C261.287 13.9155 260.155 12.7837 257.609 12.7837C256.477 12.7837 255.628 12.7837 255.345 13.0667V18.1597H255.062Z"
                        fill="black"
                    ></path>
                    <path
                        d="M87.4 104.7H77.9V116.6H87.5C91.6 116.6 93.9 114.5 93.9 110.7C94 107.5 91.5 104.7 87.4 104.7Z"
                        fill="black"
                    ></path>
                    <path
                        d="M42.4 124H53.7L48 107.2L42.4 124Z"
                        fill="black"
                    ></path>
                    <path
                        d="M121.8 104.2C119.5 104.2 117.1 105.2 115.5 106.8C112.8 109.7 112.6 114.6 112.6 118.2C112.6 121.8 112.7 126.7 115.5 129.6C117.1 131.3 119.5 132.2 121.8 132.2C124.1 132.2 126.5 131.2 128.1 129.6C130.8 126.7 131 121.9 131 118.2C131 114.6 130.9 109.6 128.1 106.8C126.5 105.2 124.1 104.2 121.8 104.2Z"
                        fill="black"
                    ></path>
                    <path
                        d="M182.6 124H193.9L188.2 107.2L182.6 124Z"
                        fill="black"
                    ></path>
                    <path
                        d="M0 0V236.5H236.5V0H0ZM58.5 138.1L55.9 130.5H40.1L37.5 138.1H30.3L44.2 98.4H52.1L66 138.1H58.5ZM101.2 138.1H93.4L86.8 123.1H78V138.1H70.7V98.4H87.4C96.9 98.4 101.2 104.3 101.2 110.7C101.2 116 98.3 120.2 93.9 121.8L101.2 138.1ZM133.2 134.3C130.2 137.3 126 138.8 121.8 138.8C117.6 138.8 113.4 137.3 110.4 134.3C106.1 130 105.4 124.7 105.4 118.2C105.4 111.7 106.1 106.4 110.4 102.1C113.4 99.1 117.6 97.6 121.8 97.6C126.1 97.6 130.2 99.1 133.2 102.1C137.5 106.4 138.2 111.7 138.2 118.2C138.2 124.8 137.5 130 133.2 134.3ZM159.7 138.1H152.4V122.9L138.6 98.4H146.4L156.2 115.5L166 98.4H173.5L159.6 122.9V138.1H159.7ZM198.7 138.1L196.1 130.5H180.3L177.7 138.1H170.4L184.3 98.4H192.2L206.1 138.1H198.7Z"
                        fill="black"
                    ></path>
                </svg>
                <div className={classes.headerContent}>
                    <Typography
                        variant="h3"
                        component="h1"
                        className={classes.headerTitle}
                    >
                        ROI Calculator
                    </Typography>
                    <div className={classes.headerContentRight}>
                        <div className={classes.headerProgress}>
                            <Typography>
                                <Link
                                    to="/your-facility?unlock=true"
                                    className={classes.progressTextActive}
                                >
                                    Your Facility
                                </Link>
                            </Typography>
                            <Typography>
                                <Link
                                    to="/aroya-plans?unlock=true"
                                    className={classes.progressText}
                                >
                                    AROYA Plans
                                </Link>
                            </Typography>
                            <Typography
                                className={classes.progressTextDisabled}
                            >
                                Your Plan
                            </Typography>
                        </div>
                        <div className={classes.headerStep}>
                            <Typography
                                variant="h3"
                                className={classes.stepText}
                            >
                                01
                            </Typography>
                            <svg
                                width="73"
                                height="65"
                                viewBox="0 0 73 65"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={classes.arrowRight}
                            >
                                <path
                                    d="M0.787005 27.4489L0.787005 37.2489L54.287 37.2489L33.887 57.7489L40.887 64.6489L72.987 32.3489L40.887 0.0489213L33.887 7.04892L54.287 27.4489L0.787005 27.4489Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className={classes.headerProgressLaptop}>
                    <Typography>
                        <Link
                            to="/your-facility?unlock=true"
                            className={classes.progressTextActive}
                        >
                            Your Facility
                        </Link>
                    </Typography>
                    <Typography>
                        <Link
                            to="/aroya-plans?unlock=true"
                            className={classes.progressText}
                        >
                            AROYA Plans
                        </Link>
                    </Typography>
                    <Typography className={classes.progressTextDisabled}>
                        Your Plan
                    </Typography>
                </div>
            </header>
            <main className={classes.main}>
                <section>
                    <Grid container spacing={6}>
                        <Grid item lg={4} md={5} xs={12}>
                            <div className={classes.mainText}>
                                <Typography
                                    variant="h4"
                                    className={classes.textTitle}
                                >
                                    Tell us more about{' '}
                                    <span
                                        id="companyRef"
                                        ref={companyRef}
                                    ></span>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className={classes.textBody}
                                >
                                    With this data we can calculate your
                                    potential ROI based on the correct sensor
                                    density for your operation.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg={8} md={7} xs={12}>
                            <div className={classes.mainFields}>
                                <form
                                    action="https://go.meter.group/l/304411/2022-06-15/254cvs"
                                    method="post"
                                    // onSubmit={handleFormSubmit}
                                >
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            sm={6}
                                            xs={12}
                                            className={classes.hiddenInputField}
                                        >
                                            <div
                                                id="emailRef"
                                                ref={emailRef}
                                            ></div>
                                        </Grid>
                                        <Grid
                                            item
                                            sm={6}
                                            xs={12}
                                            className={classes.hiddenInputField}
                                        >
                                            <input
                                                className="MuiInputBase-input MuiOutlinedInput-input"
                                                id="websiteComments"
                                                name="websiteComments"
                                                type="text"
                                                readOnly
                                                value={`
                                                AROYA Calculator - 
                                                sqFtCanopy: ${sqFtCanopy} | 
                                                gSqFtHarvest: ${gSqFtHarvest} | 
                                                wholesalePrice: ${wholesalePrice} | 
                                                costToProduce: ${costToProduce} | 
                                                harvestsPerYr: ${harvestsPerYr} | 
                                                propRooms: ${propRooms} | 
                                                vegRooms: ${vegRooms} | 
                                                flowerRooms: ${flowerRooms} | 
                                                dryingRooms: ${dryingRooms}
                                                `}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            sm={6}
                                            xs={12}
                                            className={
                                                classes.inputFieldContainer
                                            }
                                        >
                                            <TextField
                                                value={sqFtCanopy}
                                                onChange={handleSqFtCanopyInput}
                                                name="sqFtCanopy"
                                                label="Square feet of canopy"
                                                variant="outlined"
                                                color="secondary"
                                                className={classes.inputField}
                                                fullWidth
                                                inputProps={{
                                                    step: 'any',
                                                    min: 0,
                                                    max: 1000000,
                                                    type: 'number',
                                                    'aria-labelledby':
                                                        'Square feet of canopy',
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            sm={6}
                                            xs={12}
                                            className={
                                                classes.inputFieldContainer
                                            }
                                        >
                                            <FormControl
                                                variant="outlined"
                                                color="secondary"
                                                fullWidth
                                            >
                                                <InputLabel htmlFor="current-g-sq-ft-harvest">
                                                    Current g / sq ft / harvest
                                                </InputLabel>
                                                <OutlinedInput
                                                    value={gSqFtHarvest}
                                                    name="gSqFtHarvest"
                                                    onChange={
                                                        handleGSqFtHarvestInput
                                                    }
                                                    id="current-g-sq-ft-harvest"
                                                    type="number"
                                                    label="Current g / sq ft / harvest"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            sm={6}
                                            xs={12}
                                            className={
                                                classes.inputFieldContainer
                                            }
                                        >
                                            <TextField
                                                value={wholesalePrice}
                                                onChange={
                                                    handleWholesalePriceInput
                                                }
                                                label="State wholesale price"
                                                variant="outlined"
                                                color="secondary"
                                                className={classes.inputField}
                                                fullWidth
                                                inputProps={{
                                                    step: 'any',
                                                    min: 0,
                                                    max: 30000,
                                                    type: 'number',
                                                    'aria-labelledby':
                                                        'State wholesale price',
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            sm={6}
                                            xs={12}
                                            className={
                                                classes.inputFieldContainer
                                            }
                                        >
                                            <TextField
                                                value={costToProduce}
                                                onChange={
                                                    handleCostToProduceInput
                                                }
                                                label="Cost to produce (lb)"
                                                variant="outlined"
                                                color="secondary"
                                                className={classes.inputField}
                                                fullWidth
                                                inputProps={{
                                                    step: 'any',
                                                    min: 0,
                                                    max: 30000,
                                                    type: 'number',
                                                    'aria-labelledby':
                                                        'Cost to produce (lb)',
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            sm={6}
                                            xs={12}
                                            className={
                                                classes.inputFieldContainer
                                            }
                                        >
                                            <TextField
                                                value={harvestsPerYr}
                                                onChange={
                                                    handleHarvestsPerYrInput
                                                }
                                                label="Harvests per year"
                                                variant="outlined"
                                                color="secondary"
                                                className={classes.inputField}
                                                fullWidth
                                                inputProps={{
                                                    step: 'any',
                                                    min: 2,
                                                    max: 7,
                                                    type: 'number',
                                                    'aria-labelledby':
                                                        'Harvests per year',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className={classes.roomsFields}>
                                        <Typography
                                            variant="h5"
                                            className={classes.roomsTitle}
                                        >
                                            How many rooms are in your facility?
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                sm={6}
                                                xs={12}
                                                className={
                                                    classes.inputFieldContainer
                                                }
                                            >
                                                <TextField
                                                    value={propRooms}
                                                    onChange={
                                                        handlePropRoomsInput
                                                    }
                                                    label="Propagation rooms"
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={
                                                        classes.inputField
                                                    }
                                                    fullWidth
                                                    inputProps={{
                                                        step: 'any',
                                                        min: 0,
                                                        max: 100,
                                                        type: 'number',
                                                        'aria-labelledby':
                                                            'Propagation rooms',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                sm={6}
                                                xs={12}
                                                className={
                                                    classes.inputFieldContainer
                                                }
                                            >
                                                <TextField
                                                    value={vegRooms}
                                                    onChange={
                                                        handleVegRoomsInput
                                                    }
                                                    label="Veg rooms"
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={
                                                        classes.inputField
                                                    }
                                                    fullWidth
                                                    inputProps={{
                                                        step: 'any',
                                                        min: 0,
                                                        max: 100,
                                                        type: 'number',
                                                        'aria-labelledby':
                                                            'Veg rooms',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                sm={6}
                                                xs={12}
                                                className={
                                                    classes.inputFieldContainer
                                                }
                                            >
                                                <TextField
                                                    value={flowerRooms}
                                                    onChange={
                                                        handleFlowerRoomsInput
                                                    }
                                                    label="Flower rooms"
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={
                                                        classes.inputField
                                                    }
                                                    fullWidth
                                                    inputProps={{
                                                        step: 'any',
                                                        min: 0,
                                                        max: 100,
                                                        type: 'number',
                                                        'aria-labelledby':
                                                            'Flower rooms',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                sm={6}
                                                xs={12}
                                                className={
                                                    classes.inputFieldContainer
                                                }
                                            >
                                                <TextField
                                                    value={dryingRooms}
                                                    onChange={
                                                        handleDryingRoomsInput
                                                    }
                                                    label="Drying rooms"
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={
                                                        classes.inputField
                                                    }
                                                    fullWidth
                                                    inputProps={{
                                                        step: 'any',
                                                        min: 0,
                                                        max: 100,
                                                        type: 'number',
                                                        'aria-labelledby':
                                                            'Drying rooms',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <div
                                            className={
                                                classes.inputLinkContainer
                                            }
                                        >
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="large"
                                                disableElevation
                                                className={classes.inputLink}
                                                type="submit"
                                            >
                                                Show Recommended Plans
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </section>
                <footer className={classes.footer}>
                    <Typography
                        variant="body2"
                        className={classes.privacyTitle}
                    >
                        We never share your data.
                    </Typography>
                    <Typography variant="body2" className={classes.privacyLink}>
                        <a
                            href="https://www.metergroup.com/en/meter-group/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy policy statement
                        </a>
                    </Typography>
                </footer>
            </main>
        </div>
    );
};

export default YourFacility;
