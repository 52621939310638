import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import GlobalContextProvider from './contexts/GlobalContext';
import App from './App';
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffffff',
        },
        secondary: {
            main: '#000000',
        },
        success: {
            main: '#42b347',
        },
        error: {
            main: '#ef4a4a',
        },
        background: {
            default: '#ffffff',
        },
    },
    typography: {
        fontFamily: [
            'Akkurat Pro',
            'Helvetica Neue',
            'Helvetica',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 800,
            lg: 1100,
            xl: 1250,
        },
    },
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <GlobalContextProvider>
            <App />
        </GlobalContextProvider>
    </ThemeProvider>,
    document.getElementById('calculator')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
