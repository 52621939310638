import React from 'react';
import {
    Page,
    Text,
    Svg,
    Path,
    View,
    Document,
    StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
    },
    frame: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    header: {
        marginBottom: 10,
    },
    headerRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        marginLeft: '15px',
    },
    table: {
        width: '100%',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '5px 0',
    },
    tableDataWithBr: {
        width: '20%',
    },
    tableDataWithBrWrapper: {
        textAlign: 'center',
    },
    tableTitle: {
        fontSize: '20px',
        width: '20%',
    },
    tableCategory: {
        fontSize: '16px',
        width: '20%',
        textAlign: 'center',
    },
    tableSubTitle: {
        fontSize: '10px',
        width: '20%',
        textAlign: 'right',
        color: '#6a6a6a',
    },
    tableDataBig: {
        fontSize: '14px',
        width: '20%',
        textAlign: 'center',
    },
    tableDataSmall: {
        fontSize: '10px',
    },
    tableDataSmallBold: {
        fontSize: '12px',
        fontWeight: 'bold',
    },
    tableDataWithBrBig: {
        fontSize: '14px',
    },
    tableDataWithBrSmall: {
        fontSize: '9px',
    },
    tableSectionTitle: {
        padding: '5px 0',
        fontSize: '14px',
    },
    tableData: {
        fontSize: '10px',
        width: '20%',
        textAlign: 'center',
    },
    tableDataNo: {
        fontSize: '10px',
        width: '20%',
        textAlign: 'center',
        color: '#bbb',
    },
    tableHasBr: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableDataMinWidth: {
        minWidth: '60px',
        margin: '2px 0',
        fontSize: '10px',
    },
    tableDataFlex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: '40px',
    },
    tableDataNormal: {
        fontSize: '10px',
    },
    tableDataMinWidthBig: {
        minWidth: '70px',
        margin: '2px 0',
        fontSize: '14px',
    },
    tableDataFlexBig: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: '30px',
    },
    tableDataNormalBig: {
        fontSize: '14px',
    },
    dataTransmission: {
        fontSize: '8px',
        color: '#bbb',
        marginBottom: '6px',
    },
    logo: {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
    },
    footerContainer: {
        position: 'absolute',
        bottom: '30px',
        left: '560px',
        width: '1000px',
    },
    footerText: {
        fontStyle: 'italic',
        fontSize: '9px',
    },
});

const PdfDoc = ({
    goRevenueIncrease,
    proRevenueIncrease,
    eliteRevenueIncrease,
    unlimitedRevenueIncrease,
    goLbsCultivation,
    proLbsCultivation,
    eliteLbsCultivation,
    unlimitedLbsCultivation,
    goSubstrateCount,
    proSubstrateCount,
    eliteSubstrateCount,
    unlimitedSubstrateCount,
    goClimateCount,
    proClimateCount,
    eliteClimateCount,
    unlimitedClimateCount,
    goRepeaterCount,
    proRepeaterCount,
    eliteRepeaterCount,
    unlimitedRepeaterCount,
    goGatewayCount,
    proGatewayCount,
    eliteGatewayCount,
    unlimitedGatewayCount,
    goTotalHardwareCost,
    proTotalHardwareCost,
    eliteTotalHardwareCost,
    unlimitedTotalHardwareCost,
    proSoftwareFee,
    eliteSubscriptionCost,
    unlimitedSubscriptionCost,
    baseResults,
    costToProduce,
    wholesalePrice,
}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    });

    function computeMonthLease(totalCost, dpCost) {
        const pv = totalCost - dpCost;
        const rate = 0.08 / 12;
        const nper = 4 * 12;
        return (pv * rate) / (1 - (1 + rate) ** -nper);
    }

    let profits = {
        goYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation +
                ((baseResults.modelRevenue + goRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation +
                    ((baseResults.modelRevenue + goRevenueIncrease) * 0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation +
                        ((baseResults.modelRevenue + goRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 2 +
                ((baseResults.modelRevenue + goRevenueIncrease * 2) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 2 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 2 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 3 +
                ((baseResults.modelRevenue + goRevenueIncrease * 3) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 3 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 3 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 4 +
                ((baseResults.modelRevenue + goRevenueIncrease * 4) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 4 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 4 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 5 +
                ((baseResults.modelRevenue + goRevenueIncrease * 5) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 5 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 5 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 6 +
                ((baseResults.modelRevenue + goRevenueIncrease * 6) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 6 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 6 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 7 +
                ((baseResults.modelRevenue + goRevenueIncrease * 7) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 7 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 7 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        goYearTwo:
            (baseResults.lbsProduced +
                goLbsCultivation * 8 +
                ((baseResults.modelRevenue + goRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                goLbsCultivation * 8 +
                ((baseResults.modelRevenue + goRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    goLbsCultivation * 8 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    goLbsCultivation * 8 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                goLbsCultivation * 9 +
                ((baseResults.modelRevenue + goRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 9 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 9 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 9 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 9) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 10 +
                ((baseResults.modelRevenue + goRevenueIncrease * 10) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 10 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 10 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 10 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 10) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 11 +
                ((baseResults.modelRevenue + goRevenueIncrease * 11) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 11 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 11 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 11 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 11) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        proYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation +
                ((baseResults.modelRevenue + proRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation +
                    ((baseResults.modelRevenue + proRevenueIncrease) * 0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation +
                        ((baseResults.modelRevenue + proRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 2 +
                ((baseResults.modelRevenue + proRevenueIncrease * 2) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 2 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 2 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 3 +
                ((baseResults.modelRevenue + proRevenueIncrease * 3) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 3 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 3 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 4 +
                ((baseResults.modelRevenue + proRevenueIncrease * 4) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 4 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 4 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 5 +
                ((baseResults.modelRevenue + proRevenueIncrease * 5) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 5 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 5 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 6 +
                ((baseResults.modelRevenue + proRevenueIncrease * 6) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 6 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 6 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 7 +
                ((baseResults.modelRevenue + proRevenueIncrease * 7) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 7 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 7 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 8 +
                ((baseResults.modelRevenue + proRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 8 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 8 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 8 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 8) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        proYearTwo:
            (baseResults.lbsProduced +
                proLbsCultivation * 9 +
                ((baseResults.modelRevenue + proRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                proLbsCultivation * 9 +
                ((baseResults.modelRevenue + proRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    proLbsCultivation * 9 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    proLbsCultivation * 9 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                proLbsCultivation * 10 +
                ((baseResults.modelRevenue + proRevenueIncrease * 10) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 10 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 10 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 10 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 10) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 11 +
                ((baseResults.modelRevenue + proRevenueIncrease * 11) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 11 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 11 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 11 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 11) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        eliteYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation +
                ((baseResults.modelRevenue + eliteRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation +
                    ((baseResults.modelRevenue + eliteRevenueIncrease) * 0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation +
                        ((baseResults.modelRevenue + eliteRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 2 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 2) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 2 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 2 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 3 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 3) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 3 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 3 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 4 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 4) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 4 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 4 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 5 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 5) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 5 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 5 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 6 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 6) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 6 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 6 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 7 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 7) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 7 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 7 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 8 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 8 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 8 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 8 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 8) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 9 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 9 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 9 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 9 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 9) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        eliteYearTwo:
            (baseResults.lbsProduced +
                eliteLbsCultivation * 10 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 10) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 10 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 10) *
                    0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    eliteLbsCultivation * 10 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    eliteLbsCultivation * 10 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 11 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 11) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 11 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 11 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 11 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 11) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        unlimitedYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation +
                        ((baseResults.modelRevenue + unlimitedRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 2 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 2) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 2 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 2 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 3 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 3) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 3 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 3 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 4 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 4) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 4 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 4 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 5 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 5) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 5 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 5 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 6 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 6) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 6 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 6 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 7 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 7) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 7 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 7 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 8 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 8) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 8 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 8 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 8 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 8) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 9 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 9) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 9 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 9 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 9 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 9) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 10 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 10) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 10 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 10 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 10 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 10) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        unlimitedYearTwo:
            (baseResults.lbsProduced +
                unlimitedLbsCultivation * 11 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 11) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 11 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 11) *
                    0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    unlimitedLbsCultivation * 11 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    unlimitedLbsCultivation * 11 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        unlimitedYearThree:
            (baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
    };

    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.frame}>
                    {/* <View style={styles.header}>
                        <View style={styles.headerRow}>
                            <Svg
                                width="75"
                                height="75"
                                viewBox="0 0 276 237"
                                fill="none"
                            >
                                <Path
                                    d="M276 18.7256C276 28.9116 268.078 36.8341 257.891 36.8341C247.705 36.8341 239.5 28.9116 239.5 18.7256C239.5 8.82249 247.705 0.900002 257.891 0.900002C268.078 1.18295 276 9.10543 276 18.7256ZM244.027 18.7256C244.027 26.6481 249.969 32.8729 257.891 32.8729C265.814 32.8729 271.473 26.6481 271.473 18.7256C271.473 10.8031 265.814 4.29535 257.891 4.29535C249.969 4.5783 244.027 11.0861 244.027 18.7256ZM255.062 28.0628H250.818V10.2372C252.516 9.95427 254.779 9.67132 257.609 9.67132C261.004 9.67132 262.419 10.2372 263.833 11.0861C264.682 11.9349 265.531 13.3496 265.531 15.0473C265.531 17.0279 264.116 18.4426 261.853 19.2915V19.5744C263.55 20.1403 264.682 21.5551 264.965 23.8186C265.531 26.6481 265.814 27.4969 266.38 28.3458H261.853C261.287 27.7799 261.004 26.0822 260.438 24.1016C260.155 22.1209 259.023 21.2721 256.76 21.2721H254.779V28.0628H255.062ZM255.062 18.1597H257.043C259.306 18.1597 261.287 17.3109 261.287 15.6132C261.287 13.9155 260.155 12.7837 257.609 12.7837C256.477 12.7837 255.628 12.7837 255.345 13.0667V18.1597H255.062Z"
                                    fill="black"
                                ></Path>
                                <Path
                                    d="M87.4 104.7H77.9V116.6H87.5C91.6 116.6 93.9 114.5 93.9 110.7C94 107.5 91.5 104.7 87.4 104.7Z"
                                    fill="black"
                                ></Path>
                                <Path
                                    d="M42.4 124H53.7L48 107.2L42.4 124Z"
                                    fill="black"
                                ></Path>
                                <Path
                                    d="M121.8 104.2C119.5 104.2 117.1 105.2 115.5 106.8C112.8 109.7 112.6 114.6 112.6 118.2C112.6 121.8 112.7 126.7 115.5 129.6C117.1 131.3 119.5 132.2 121.8 132.2C124.1 132.2 126.5 131.2 128.1 129.6C130.8 126.7 131 121.9 131 118.2C131 114.6 130.9 109.6 128.1 106.8C126.5 105.2 124.1 104.2 121.8 104.2Z"
                                    fill="black"
                                ></Path>
                                <Path
                                    d="M182.6 124H193.9L188.2 107.2L182.6 124Z"
                                    fill="black"
                                ></Path>
                                <Path
                                    d="M0 0V236.5H236.5V0H0ZM58.5 138.1L55.9 130.5H40.1L37.5 138.1H30.3L44.2 98.4H52.1L66 138.1H58.5ZM101.2 138.1H93.4L86.8 123.1H78V138.1H70.7V98.4H87.4C96.9 98.4 101.2 104.3 101.2 110.7C101.2 116 98.3 120.2 93.9 121.8L101.2 138.1ZM133.2 134.3C130.2 137.3 126 138.8 121.8 138.8C117.6 138.8 113.4 137.3 110.4 134.3C106.1 130 105.4 124.7 105.4 118.2C105.4 111.7 106.1 106.4 110.4 102.1C113.4 99.1 117.6 97.6 121.8 97.6C126.1 97.6 130.2 99.1 133.2 102.1C137.5 106.4 138.2 111.7 138.2 118.2C138.2 124.8 137.5 130 133.2 134.3ZM159.7 138.1H152.4V122.9L138.6 98.4H146.4L156.2 115.5L166 98.4H173.5L159.6 122.9V138.1H159.7ZM198.7 138.1L196.1 130.5H180.3L177.7 138.1H170.4L184.3 98.4H192.2L206.1 138.1H198.7Z"
                                    fill="black"
                                ></Path>
                            </Svg>
                            <Text
                                style={styles.title}
                            >{`AROYA ROI Quote`}</Text>
                        </View>
                    </View> */}
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableTitle}>PLANS</Text>
                            <Text style={styles.tableCategory}>AROYA GO</Text>
                            <Text style={styles.tableCategory}>AROYA PRO</Text>
                            <Text style={styles.tableCategory}>
                                AROYA Elite
                            </Text>
                            <Text style={styles.tableCategory}>
                                AROYA Unlimited
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Yield increases start in
                            </Text>
                            <Text style={styles.tableDataBig}>6 Months</Text>
                            <Text style={styles.tableDataBig}>5 Months</Text>
                            <Text style={styles.tableDataBig}>4 Months</Text>
                            <Text style={styles.tableDataBig}>3 Months</Text>
                        </View>
                        {/* <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Yield increase each month
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {goLbsCultivation} lbs
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {proLbsCultivation} lbs
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {eliteLbsCultivation} lbs
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {unlimitedLbsCultivation} lbs
                            </Text>
                        </View> */}
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Profit increase year 1
                            </Text>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <Text style={styles.tableDataSmallBold}>
                                        Contact Sales
                                    </Text>
                                    <Text style={styles.tableDataWithBrSmall}>
                                        2% Increase
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <Text style={styles.tableDataWithBrBig}>
                                        {formatter.format(
                                            Math.round(
                                                profits.proYearOne -
                                                    proSoftwareFee -
                                                    Math.round(
                                                        proTotalHardwareCost *
                                                            0.2
                                                    ) -
                                                    Math.round(
                                                        computeMonthLease(
                                                            proTotalHardwareCost,
                                                            Math.round(
                                                                proTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                    0
                                                    ? 0
                                                    : profits.proYearOne -
                                                          proSoftwareFee -
                                                          Math.round(
                                                              proTotalHardwareCost *
                                                                  0.2
                                                          ) -
                                                          Math.round(
                                                              computeMonthLease(
                                                                  proTotalHardwareCost,
                                                                  Math.round(
                                                                      proTotalHardwareCost *
                                                                          0.2
                                                                  )
                                                              )
                                                          ) *
                                                              12
                                            )
                                        )}
                                    </Text>
                                    <Text style={styles.tableDataWithBrSmall}>
                                        5% Increase
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <Text style={styles.tableDataWithBrBig}>
                                        {formatter.format(
                                            Math.round(
                                                profits.eliteYearOne -
                                                    eliteSubscriptionCost -
                                                    Math.round(
                                                        eliteTotalHardwareCost *
                                                            0.2
                                                    ) -
                                                    Math.round(
                                                        computeMonthLease(
                                                            eliteTotalHardwareCost,
                                                            Math.round(
                                                                eliteTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                    0
                                                    ? 0
                                                    : profits.eliteYearOne -
                                                          eliteSubscriptionCost -
                                                          Math.round(
                                                              eliteTotalHardwareCost *
                                                                  0.2
                                                          ) -
                                                          Math.round(
                                                              computeMonthLease(
                                                                  eliteTotalHardwareCost,
                                                                  Math.round(
                                                                      eliteTotalHardwareCost *
                                                                          0.2
                                                                  )
                                                              )
                                                          ) *
                                                              12
                                            )
                                        )}
                                    </Text>
                                    <Text style={styles.tableDataWithBrSmall}>
                                        10% Increase
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <Text style={styles.tableDataWithBrBig}>
                                        {formatter.format(
                                            Math.round(
                                                profits.unlimitedYearOne -
                                                    unlimitedSubscriptionCost -
                                                    Math.round(
                                                        unlimitedTotalHardwareCost *
                                                            0.2
                                                    ) -
                                                    Math.round(
                                                        computeMonthLease(
                                                            unlimitedTotalHardwareCost,
                                                            Math.round(
                                                                unlimitedTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                    0
                                                    ? 0
                                                    : profits.unlimitedYearOne -
                                                          unlimitedSubscriptionCost -
                                                          Math.round(
                                                              unlimitedTotalHardwareCost *
                                                                  0.2
                                                          ) -
                                                          Math.round(
                                                              computeMonthLease(
                                                                  unlimitedTotalHardwareCost,
                                                                  Math.round(
                                                                      unlimitedTotalHardwareCost *
                                                                          0.2
                                                                  )
                                                              )
                                                          ) *
                                                              12
                                            )
                                        )}
                                    </Text>
                                    <Text style={styles.tableDataWithBrSmall}>
                                        15% Increase
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Profit increase year 2
                            </Text>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <Text style={styles.tableDataSmallBold}>
                                        Contact Sales
                                    </Text>
                                    <Text style={styles.tableDataWithBrSmall}>
                                        2% Increase
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <Text style={styles.tableDataWithBrBig}>
                                        {formatter.format(
                                            Math.round(
                                                profits.proYearTwo -
                                                    proSoftwareFee -
                                                    Math.round(
                                                        computeMonthLease(
                                                            proTotalHardwareCost,
                                                            Math.round(
                                                                proTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                    0
                                                    ? 0
                                                    : profits.proYearTwo -
                                                          proSoftwareFee -
                                                          Math.round(
                                                              computeMonthLease(
                                                                  proTotalHardwareCost,
                                                                  Math.round(
                                                                      proTotalHardwareCost *
                                                                          0.2
                                                                  )
                                                              )
                                                          ) *
                                                              12
                                            )
                                        )}
                                    </Text>
                                    <Text style={styles.tableDataWithBrSmall}>
                                        5% Increase
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <Text style={styles.tableDataWithBrBig}>
                                        {formatter.format(
                                            Math.round(
                                                profits.eliteYearTwo -
                                                    eliteSubscriptionCost -
                                                    Math.round(
                                                        computeMonthLease(
                                                            eliteTotalHardwareCost,
                                                            Math.round(
                                                                eliteTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                    0
                                                    ? 0
                                                    : profits.eliteYearTwo -
                                                          eliteSubscriptionCost -
                                                          Math.round(
                                                              computeMonthLease(
                                                                  eliteTotalHardwareCost,
                                                                  Math.round(
                                                                      eliteTotalHardwareCost *
                                                                          0.2
                                                                  )
                                                              )
                                                          ) *
                                                              12
                                            )
                                        )}
                                    </Text>
                                    <Text style={styles.tableDataWithBrSmall}>
                                        10% Increase
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <Text style={styles.tableDataWithBrBig}>
                                        {formatter.format(
                                            Math.round(
                                                profits.unlimitedYearTwo -
                                                    unlimitedSubscriptionCost -
                                                    Math.round(
                                                        computeMonthLease(
                                                            unlimitedTotalHardwareCost,
                                                            Math.round(
                                                                unlimitedTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                    0
                                                    ? 0
                                                    : profits.unlimitedYearTwo -
                                                          unlimitedSubscriptionCost -
                                                          Math.round(
                                                              computeMonthLease(
                                                                  unlimitedTotalHardwareCost,
                                                                  Math.round(
                                                                      unlimitedTotalHardwareCost *
                                                                          0.2
                                                                  )
                                                              )
                                                          ) *
                                                              12
                                            )
                                        )}
                                    </Text>
                                    <Text style={styles.tableDataWithBrSmall}>
                                        15% Increase
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSectionTitle}>
                                Product Features
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>Users</Text>
                            <Text style={styles.tableDataNo}>No</Text>
                            <Text style={styles.tableData}>Unlimited</Text>
                            <Text style={styles.tableData}>Unlimited</Text>
                            <Text style={styles.tableData}>Unlimited</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                METRC Integration
                            </Text>
                            <Text style={styles.tableDataNo}>No</Text>
                            <Text style={styles.tableDataNo}>No</Text>
                            <Text style={styles.tableData}>Yes</Text>
                            <Text style={styles.tableData}>Yes</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>Analytics</Text>
                            <Text style={styles.tableDataNo}>No</Text>
                            <Text style={styles.tableDataNo}>No</Text>
                            <Text style={styles.tableData}>Yes</Text>
                            <Text style={styles.tableData}>Yes</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Customer success calls
                            </Text>
                            <Text style={styles.tableDataNo}>No</Text>
                            <Text style={styles.tableDataNo}>No</Text>
                            <Text style={styles.tableData}>Monthly</Text>
                            <Text style={styles.tableData}>Weekly</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Guaranteed Yield Increase*
                            </Text>
                            <Text style={styles.tableDataNo}>No</Text>
                            <Text style={styles.tableDataNo}>No</Text>
                            <Text style={styles.tableDataNo}>No</Text>
                            <Text style={styles.tableData}>10%</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Cost per sensor
                            </Text>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Substrate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            -
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Climate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            -
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Repeater
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            -
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Gateway
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            -
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Substrate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $538
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.dataTransmission}>
                                            Data Transmission Included
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Climate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $538
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.dataTransmission}>
                                            Data Transmission Included
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Repeater
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $305
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Gateway
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $538
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Substrate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $414
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.dataTransmission}>
                                            Data Transmission Included
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Climate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $414
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.dataTransmission}>
                                            Data Transmission Included
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Repeater
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $254
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Gateway
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $414
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Substrate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $414
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.dataTransmission}>
                                            Data Transmission Included
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Climate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $414
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.dataTransmission}>
                                            Data Transmission Included
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Repeater
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $254
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Gateway
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            $414
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Recommended sensors
                            </Text>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Substrate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            -
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Climate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            -
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Repeater
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            -
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Gateway
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            -
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Substrate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {proSubstrateCount}
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Climate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {proClimateCount}
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Repeater
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {proRepeaterCount}
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Gateway
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {proGatewayCount}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Substrate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {eliteSubstrateCount}
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Climate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {eliteClimateCount}
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Repeater
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {eliteRepeaterCount}
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Gateway
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {eliteGatewayCount}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableDataWithBr}>
                                <View style={styles.tableDataWithBrWrapper}>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Substrate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {unlimitedSubstrateCount}
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Climate
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {unlimitedClimateCount}
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Repeater
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {unlimitedRepeaterCount}
                                        </Text>
                                    </View>
                                    <View style={styles.tableDataFlex}>
                                        <Text style={styles.tableDataMinWidth}>
                                            Gateway
                                        </Text>
                                        <Text style={styles.tableDataNormal}>
                                            {unlimitedGatewayCount}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Year 1 down payment
                            </Text>
                            <Text style={styles.tableData}>
                                <b>*Call for pricing</b>
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {formatter.format(
                                    Math.round(proTotalHardwareCost * 0.2)
                                )}
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {formatter.format(
                                    Math.round(eliteTotalHardwareCost * 0.2)
                                )}
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {formatter.format(
                                    Math.round(unlimitedTotalHardwareCost * 0.2)
                                )}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Monthly hardware lease
                            </Text>
                            <Text style={styles.tableData}>
                                <b>*Call for pricing</b>
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {formatter.format(
                                    Math.round(
                                        computeMonthLease(
                                            proTotalHardwareCost,
                                            Math.round(
                                                proTotalHardwareCost * 0.2
                                            )
                                        )
                                    )
                                )}
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {formatter.format(
                                    Math.round(
                                        computeMonthLease(
                                            eliteTotalHardwareCost,
                                            Math.round(
                                                eliteTotalHardwareCost * 0.2
                                            )
                                        )
                                    )
                                )}
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {formatter.format(
                                    Math.round(
                                        computeMonthLease(
                                            unlimitedTotalHardwareCost,
                                            Math.round(
                                                unlimitedTotalHardwareCost * 0.2
                                            )
                                        )
                                    )
                                )}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableSubTitle}>
                                Yearly subscription
                            </Text>
                            <Text style={styles.tableDataBig}>$0</Text>
                            <Text style={styles.tableDataBig}>
                                {formatter.format(proSoftwareFee)}
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {formatter.format(eliteSubscriptionCost)}
                            </Text>
                            <Text style={styles.tableDataBig}>
                                {formatter.format(unlimitedSubscriptionCost)}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.footerContainer}>
                    <Text style={styles.footerText}>
                        *For detailed pricing, please call{' '}
                        <a href="tel:+1.509.332.2756">+1.509.332.2756</a>
                    </Text>
                </View>
                <Svg
                    width="50"
                    height="50"
                    viewBox="0 0 276 237"
                    fill="none"
                    style={styles.logo}
                >
                    <Path
                        d="M276 18.7256C276 28.9116 268.078 36.8341 257.891 36.8341C247.705 36.8341 239.5 28.9116 239.5 18.7256C239.5 8.82249 247.705 0.900002 257.891 0.900002C268.078 1.18295 276 9.10543 276 18.7256ZM244.027 18.7256C244.027 26.6481 249.969 32.8729 257.891 32.8729C265.814 32.8729 271.473 26.6481 271.473 18.7256C271.473 10.8031 265.814 4.29535 257.891 4.29535C249.969 4.5783 244.027 11.0861 244.027 18.7256ZM255.062 28.0628H250.818V10.2372C252.516 9.95427 254.779 9.67132 257.609 9.67132C261.004 9.67132 262.419 10.2372 263.833 11.0861C264.682 11.9349 265.531 13.3496 265.531 15.0473C265.531 17.0279 264.116 18.4426 261.853 19.2915V19.5744C263.55 20.1403 264.682 21.5551 264.965 23.8186C265.531 26.6481 265.814 27.4969 266.38 28.3458H261.853C261.287 27.7799 261.004 26.0822 260.438 24.1016C260.155 22.1209 259.023 21.2721 256.76 21.2721H254.779V28.0628H255.062ZM255.062 18.1597H257.043C259.306 18.1597 261.287 17.3109 261.287 15.6132C261.287 13.9155 260.155 12.7837 257.609 12.7837C256.477 12.7837 255.628 12.7837 255.345 13.0667V18.1597H255.062Z"
                        fill="black"
                    ></Path>
                    <Path
                        d="M87.4 104.7H77.9V116.6H87.5C91.6 116.6 93.9 114.5 93.9 110.7C94 107.5 91.5 104.7 87.4 104.7Z"
                        fill="black"
                    ></Path>
                    <Path
                        d="M42.4 124H53.7L48 107.2L42.4 124Z"
                        fill="black"
                    ></Path>
                    <Path
                        d="M121.8 104.2C119.5 104.2 117.1 105.2 115.5 106.8C112.8 109.7 112.6 114.6 112.6 118.2C112.6 121.8 112.7 126.7 115.5 129.6C117.1 131.3 119.5 132.2 121.8 132.2C124.1 132.2 126.5 131.2 128.1 129.6C130.8 126.7 131 121.9 131 118.2C131 114.6 130.9 109.6 128.1 106.8C126.5 105.2 124.1 104.2 121.8 104.2Z"
                        fill="black"
                    ></Path>
                    <Path
                        d="M182.6 124H193.9L188.2 107.2L182.6 124Z"
                        fill="black"
                    ></Path>
                    <Path
                        d="M0 0V236.5H236.5V0H0ZM58.5 138.1L55.9 130.5H40.1L37.5 138.1H30.3L44.2 98.4H52.1L66 138.1H58.5ZM101.2 138.1H93.4L86.8 123.1H78V138.1H70.7V98.4H87.4C96.9 98.4 101.2 104.3 101.2 110.7C101.2 116 98.3 120.2 93.9 121.8L101.2 138.1ZM133.2 134.3C130.2 137.3 126 138.8 121.8 138.8C117.6 138.8 113.4 137.3 110.4 134.3C106.1 130 105.4 124.7 105.4 118.2C105.4 111.7 106.1 106.4 110.4 102.1C113.4 99.1 117.6 97.6 121.8 97.6C126.1 97.6 130.2 99.1 133.2 102.1C137.5 106.4 138.2 111.7 138.2 118.2C138.2 124.8 137.5 130 133.2 134.3ZM159.7 138.1H152.4V122.9L138.6 98.4H146.4L156.2 115.5L166 98.4H173.5L159.6 122.9V138.1H159.7ZM198.7 138.1L196.1 130.5H180.3L177.7 138.1H170.4L184.3 98.4H192.2L206.1 138.1H198.7Z"
                        fill="black"
                    ></Path>
                </Svg>
            </Page>
        </Document>
    );
};

export default PdfDoc;
