import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDoc from '../components/PdfDoc';
import {
    makeStyles,
    Typography,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Button,
} from '@material-ui/core';
import { GlobalContext } from '../contexts/GlobalContext';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import clsx from 'clsx';
import arrowThin from '../images/arrow-thin.svg';
import DemoModal from '../components/DemoModal';
import postscribe from 'postscribe';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles((theme) => ({
    root: {},
    chart: {
        fontFamily: 'Akkurat Pro, Helvetica Neue, Helvetica, Arial, sans-serif',
        '& .recharts-default-legend': {
            marginTop: '60px !important',
            [theme.breakpoints.down('sm')]: {
                marginTop: '30px !important',
            },
        },
        '& .recharts-legend-item': {
            marginRight: '30px !important',
            '&.legend-item-4': {
                marginRight: '0 !important',
            },
        },
        '& .recharts-rectangle.recharts-tooltip-cursor': {
            fill: '#f5f5f5 !important',
        },
    },
    header: {
        marginBottom: '2rem',
        borderBottom: '2px solid #000',
    },
    headerContent: {
        padding: '0 0 1.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    headerContentLeft: {
        marginRight: '4.5rem',
        maxWidth: '500px',
        [theme.breakpoints.down('xs')]: {
            marginRight: '0',
        },
    },
    headerContentRight: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    demoButtonWrapper: { marginBottom: '20px' },
    demoButtonWrapperMobile: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            margin: '1rem 0',
        },
    },
    headerContentRightWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        minWidth: 'fit-content',
    },
    headerProgress: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginRight: '4.5rem',
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
    headerProgressLaptop: {
        display: 'none',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginBottom: '2rem',
        marginLeft: 'auto',
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    headerSubtitle: {
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem',
        },
    },
    headerTitle: {
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8rem',
        },
    },
    progressText: {
        color: 'rgb(191, 191, 191)',
        margin: '0 1rem',
        [theme.breakpoints.down('xs')]: {
            margin: '0 0.7rem',
        },
    },
    progressTextActive: {
        color: '#000',
        margin: '0 1rem',
        [theme.breakpoints.down('xs')]: {
            margin: '0 0.7rem',
        },
    },
    headerStep: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
    },
    stepText: {
        fontWeight: 700,
        marginRight: '1.5rem',
    },
    arrowRight: {
        width: '50px',
    },
    chartContainer: {
        padding: '2rem 0 4rem',
        [theme.breakpoints.down('md')]: {
            padding: '2rem 0 4rem',
        },
    },
    chartWrapper: {
        width: '75%',
        height: '400px',
        position: 'relative',
        margin: '0 auto',
        [theme.breakpoints.down('lg')]: {
            height: '350px',
        },
        [theme.breakpoints.down('md')]: {
            width: '98%',
        },
    },
    chartText: {
        position: 'absolute',
        right: '-20px',
        top: '50%',
        bottom: 0,
        transform: 'translate(100%, -50%)',
        [theme.breakpoints.down('md')]: {
            left: '50%',
            right: 0,
            bottom: 0,
            transform: 'translate(-50%, 135%)',
            width: 'fit-content',
        },
    },
    chartMainTitle: {
        paddingLeft: '30px',
        paddingBottom: '15px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '2.6rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2.2rem',
            paddingLeft: '7px',
        },
    },
    chartTitle: {
        marginBottom: '30px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '2.6rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2.2rem',
            marginBottom: '20px',
        },
    },
    chartContent: {
        maxWidth: '250px',
        lineHeight: 1.3,
    },
    tableCell: {
        width: '20%',
        borderBottom: '1px solid #aaa',
        padding: '20px 30px',
        [theme.breakpoints.down('md')]: {
            padding: '15px 7px',
        },
    },
    tableTitle: {
        color: '#878787',
    },
    tableContent: {
        color: '#303030',
        lineHeight: 1,
    },
    tableContentMed: {
        fontSize: '1rem',
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            fontWeight: 400,
        },
    },
    tableContentBig: {
        fontSize: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.2rem',
        },
    },
    tableContentNormal: {
        fontSize: '1.8rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.2rem',
        },
    },
    tableContentSmall: {
        fontSize: '0.8rem',
    },
    contactSalesFont: {
        fontSize: '1.4rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    tableHasBr: {
        textAlign: 'left',
        margin: '0 auto',
        width: 'fit-content',
        '& span': {
            minWidth: '4.5rem',
            display: 'inline-block',
            [theme.breakpoints.down('sm')]: {
                minWidth: '100%',
            },
        },
    },
    dataTransmission: {
        fontSize: '0.7rem',
        color: '#bbb',
        display: 'block',
        marginBottom: '0.4rem',
    },
    tableHasBrBig: {
        textAlign: 'left',
        margin: '0 auto',
        width: 'fit-content',
        '& span': {
            minWidth: '8.5rem',
            display: 'inline-block',
            lineHeight: 1.2,
        },
    },
    hasNoBorder: {
        borderBottom: 0,
    },
    plansTable: {
        padding: '2rem 0',
        borderBottom: '2px solid #000',
        zIndex: '1',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    plansTableMobile: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            margin: '3rem 0',
            zIndex: '1',
            position: 'relative',
        },
    },
    planTitle: {
        marginBottom: '10px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    productFeatures: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.1rem',
        },
    },
    planButton: {
        textTransform: 'capitalize',
    },
    featuresTable: {
        padding: '1rem 0',
        borderBottom: '2px solid #000',
        zIndex: '1',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    costsTable: {
        padding: '1rem 0',
        borderBottom: '2px solid #000',
        zIndex: '1',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    selectsTable: {
        marginBottom: '6rem',
        zIndex: '1',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '3rem',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    editLink: {
        '&::before': {
            content: '""',
            display: 'block',
            float: 'left',
            width: '30px',
            height: '35px',
            background: `url(${arrowThin}) no-repeat center`,
            backgroundSize: '100% 100%',
            transform: 'rotate(180deg)',
            marginRight: '10px',
        },
    },
    downloadButton: {
        textTransform: 'capitalize',
        padding: '0',

        '& a': {
            color: '#fff',
            display: 'block',
            padding: '8px 20px',
        },
    },
    tableNo: {
        color: '#bbb',
    },
    isScrolled: {
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        padding: '0 3rem',
        background: '#fff',
        maxWidth: '1600px',
        boxShadow: '0 5px 10px rgb(0 0 0 / 12%)',
        zIndex: 10,
    },
    tableContainerScrolled: {
        display: 'none',
    },
    tableContainerDupe: {
        display: 'block',
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        padding: '0 3rem',
        background: '#fff',
        maxWidth: '1600px',
        zIndex: 10,
        borderBottom: '1px solid #aaa',
        [theme.breakpoints.down('lg')]: {
            padding: '0 2rem',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    dupeTable: {
        padding: 0,
    },
    chartHead: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
        },
    },
    chartHeading: {
        paddingLeft: '180px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '2rem',
            fontSize: '2rem',
        },
    },
    chartToggle: {
        paddingLeft: '60px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '3rem',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '2rem',
        },
    },
    planTableTitle: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '2.6rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2.2rem',
        },
    },
    chartMobile: {
        [theme.breakpoints.down('sm')]: {
            '& .yAxis .recharts-cartesian-axis-ticks': {
                display: 'none !important',
            },
        },
    },
    plansContainer: {
        position: 'relative',
        '&::before': {
            content: "''",
            position: 'absolute',
            background: '#eaf6f4',
            left: '60%',
            top: '0',
            right: '20%',
            bottom: '0',
            zIndex: '1',
            [theme.breakpoints.down('xs')]: {
                content: 'none',
            },
        },
    },
    bgGreen: {
        background: '#eaf6f4',
    },
    bgBlack: {
        background: '#000',
    },
    plansContainerTabs: {
        borderBottom: '2px solid #000',
        paddingTop: '2rem',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    plansContainerTab: {
        '& table': {
            borderCollapse: 'initial',
        },
    },
    mostPopular: {
        color: '#fff',
        lineHeight: 1.2,
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    mostPopularCell: {
        padding: '15px 10px',
        borderRadius: '4px 4px 0 0',
    },
}));

const AroyaPlans = () => {
    const classes = useStyles();
    const {
        // BELOW IS FROM CHART COMPONENT
        sqFtCanopy,
        gSqFtHarvest,
        harvestsPerYr,
        vegRooms,
        propRooms,
        dryingRooms,
        wholesalePrice,
        costToProduce,
        flowerRooms,
        calculateBaseResults,
        computeRevenueIncrease,
        computeDryingIntelligence,
        computeLbsCultivationIncrease,
        goRevenueIncrease,
        proRevenueIncrease,
        eliteRevenueIncrease,
        unlimitedRevenueIncrease,
        baseResults,
        dryingRevenueIncrease,
        computeSubstrateCount,
        computeSubscriptionCost,
        computeSubstrateCost,
        computeSoftwareFee,
        computeClimateCost,
        computeClimateCount,
        computeRepeaterCount,
        computeRepeaterCost,
        computeGatewayCount,
        computeGatewayCost,
        computeTotalHardwareCost,
        goSubstrateCount,
        proSubstrateCount,
        eliteSubstrateCount,
        unlimitedSubstrateCount,
        goClimateCount,
        proClimateCount,
        eliteClimateCount,
        unlimitedClimateCount,
        goRepeaterCount,
        proRepeaterCount,
        eliteRepeaterCount,
        unlimitedRepeaterCount,
        goGatewayCount,
        proGatewayCount,
        eliteGatewayCount,
        unlimitedGatewayCount,
        proSubstrateCost,
        eliteSubstrateCost,
        unlimitedSubstrateCost,
        goClimateCost,
        proClimateCost,
        eliteClimateCost,
        unlimitedClimateCost,
        goRepeaterCost,
        proRepeaterCost,
        eliteRepeaterCost,
        unlimitedRepeaterCost,
        goGatewayCost,
        proGatewayCost,
        eliteGatewayCost,
        unlimitedGatewayCost,
        goSubstrateCost,
        goLbsCultivationIncrease,
        proLbsCultivationIncrease,
        eliteLbsCultivationIncrease,
        unlimitedLbsCultivationIncrease,
        goTotalHardwareCost,
        proTotalHardwareCost,
        eliteTotalHardwareCost,
        unlimitedTotalHardwareCost,
        proSoftwareFee,
        eliteSubscriptionCost,
        unlimitedSubscriptionCost,
    } = useContext(GlobalContext);

    let unlimitedLbsCultivation =
        Math.round(unlimitedLbsCultivationIncrease * 10) / 10;
    let eliteLbsCultivation = Math.round(eliteLbsCultivationIncrease * 10) / 10;
    let proLbsCultivation = Math.round(proLbsCultivationIncrease * 10) / 10;
    let goLbsCultivation = Math.round(goLbsCultivationIncrease * 10) / 10;
    let profits = {
        goYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation +
                ((baseResults.modelRevenue + goRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation +
                    ((baseResults.modelRevenue + goRevenueIncrease) * 0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation +
                        ((baseResults.modelRevenue + goRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 2 +
                ((baseResults.modelRevenue + goRevenueIncrease * 2) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 2 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 2 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 3 +
                ((baseResults.modelRevenue + goRevenueIncrease * 3) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 3 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 3 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 4 +
                ((baseResults.modelRevenue + goRevenueIncrease * 4) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 4 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 4 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 5 +
                ((baseResults.modelRevenue + goRevenueIncrease * 5) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 5 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 5 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 6 +
                ((baseResults.modelRevenue + goRevenueIncrease * 6) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 6 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 6 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 7 +
                ((baseResults.modelRevenue + goRevenueIncrease * 7) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 7 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 7 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        goYearTwo:
            (baseResults.lbsProduced +
                goLbsCultivation * 8 +
                ((baseResults.modelRevenue + goRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                goLbsCultivation * 8 +
                ((baseResults.modelRevenue + goRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    goLbsCultivation * 8 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    goLbsCultivation * 8 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                goLbsCultivation * 9 +
                ((baseResults.modelRevenue + goRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 9 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 9 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 9 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 9) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 10 +
                ((baseResults.modelRevenue + goRevenueIncrease * 10) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 10 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 10 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 10 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 10) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 11 +
                ((baseResults.modelRevenue + goRevenueIncrease * 11) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 11 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 11 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 11 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 11) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        proYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation +
                ((baseResults.modelRevenue + proRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation +
                    ((baseResults.modelRevenue + proRevenueIncrease) * 0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation +
                        ((baseResults.modelRevenue + proRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 2 +
                ((baseResults.modelRevenue + proRevenueIncrease * 2) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 2 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 2 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 3 +
                ((baseResults.modelRevenue + proRevenueIncrease * 3) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 3 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 3 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 4 +
                ((baseResults.modelRevenue + proRevenueIncrease * 4) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 4 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 4 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 5 +
                ((baseResults.modelRevenue + proRevenueIncrease * 5) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 5 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 5 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 6 +
                ((baseResults.modelRevenue + proRevenueIncrease * 6) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 6 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 6 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 7 +
                ((baseResults.modelRevenue + proRevenueIncrease * 7) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 7 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 7 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 8 +
                ((baseResults.modelRevenue + proRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 8 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 8 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 8 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 8) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        proYearTwo:
            (baseResults.lbsProduced +
                proLbsCultivation * 9 +
                ((baseResults.modelRevenue + proRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                proLbsCultivation * 9 +
                ((baseResults.modelRevenue + proRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    proLbsCultivation * 9 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    proLbsCultivation * 9 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                proLbsCultivation * 10 +
                ((baseResults.modelRevenue + proRevenueIncrease * 10) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 10 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 10 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 10 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 10) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 11 +
                ((baseResults.modelRevenue + proRevenueIncrease * 11) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 11 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 11 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 11 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 11) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        eliteYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation +
                ((baseResults.modelRevenue + eliteRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation +
                    ((baseResults.modelRevenue + eliteRevenueIncrease) * 0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation +
                        ((baseResults.modelRevenue + eliteRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 2 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 2) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 2 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 2 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 3 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 3) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 3 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 3 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 4 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 4) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 4 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 4 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 5 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 5) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 5 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 5 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 6 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 6) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 6 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 6 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 7 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 7) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 7 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 7 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 8 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 8 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 8 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 8 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 8) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 9 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 9 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 9 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 9 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 9) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        eliteYearTwo:
            (baseResults.lbsProduced +
                eliteLbsCultivation * 10 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 10) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 10 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 10) *
                    0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    eliteLbsCultivation * 10 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    eliteLbsCultivation * 10 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 11 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 11) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 11 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 11 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 11 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 11) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        unlimitedYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation +
                        ((baseResults.modelRevenue + unlimitedRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 2 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 2) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 2 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 2 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 3 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 3) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 3 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 3 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 4 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 4) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 4 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 4 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 5 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 5) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 5 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 5 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 6 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 6) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 6 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 6 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 7 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 7) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 7 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 7 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 8 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 8) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 8 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 8 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 8 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 8) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 9 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 9) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 9 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 9 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 9 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 9) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 10 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 10) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 10 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 10 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 10 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 10) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        unlimitedYearTwo:
            (baseResults.lbsProduced +
                unlimitedLbsCultivation * 11 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 11) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 11 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 11) *
                    0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    unlimitedLbsCultivation * 11 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    unlimitedLbsCultivation * 11 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        unlimitedYearThree:
            (baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
    };
    let navigate = useNavigate();
    const [params] = useSearchParams();
    const unlock = params.get('unlock');

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    });

    const dataBar = [
        {
            name: 'Year 1',
            swCost: unlimitedSubscriptionCost,
            hwDp: Math.round(unlimitedTotalHardwareCost * 0.2),
            hwLease:
                Math.round(
                    computeMonthLease(
                        unlimitedTotalHardwareCost,
                        Math.round(unlimitedTotalHardwareCost * 0.2)
                    )
                ) * 12,
            profit:
                profits.unlimitedYearOne -
                    unlimitedSubscriptionCost -
                    Math.round(unlimitedTotalHardwareCost * 0.2) -
                    Math.round(
                        computeMonthLease(
                            unlimitedTotalHardwareCost,
                            Math.round(unlimitedTotalHardwareCost * 0.2)
                        )
                    ) *
                        12 <=
                0
                    ? 0
                    : profits.unlimitedYearOne -
                      unlimitedSubscriptionCost -
                      Math.round(unlimitedTotalHardwareCost * 0.2) -
                      Math.round(
                          computeMonthLease(
                              unlimitedTotalHardwareCost,
                              Math.round(unlimitedTotalHardwareCost * 0.2)
                          )
                      ) *
                          12,
            revenue:
                Math.round(unlimitedRevenueIncrease) +
                Math.round(unlimitedRevenueIncrease) * 2 +
                Math.round(unlimitedRevenueIncrease) * 3 +
                Math.round(unlimitedRevenueIncrease) * 4 +
                Math.round(unlimitedRevenueIncrease) * 5 +
                Math.round(unlimitedRevenueIncrease) * 6 +
                Math.round(unlimitedRevenueIncrease) * 7 +
                Math.round(unlimitedRevenueIncrease) * 8 +
                Math.round(unlimitedRevenueIncrease) * 9 +
                Math.round(unlimitedRevenueIncrease) * 10 +
                Math.round(dryingRevenueIncrease) * 11,
        },
        {
            name: 'Year 2',
            swCost: unlimitedSubscriptionCost,
            hwLease:
                Math.round(
                    computeMonthLease(
                        unlimitedTotalHardwareCost,
                        Math.round(unlimitedTotalHardwareCost * 0.2)
                    )
                ) * 12,
            profit:
                profits.unlimitedYearTwo -
                    unlimitedSubscriptionCost -
                    Math.round(
                        computeMonthLease(
                            unlimitedTotalHardwareCost,
                            Math.round(unlimitedTotalHardwareCost * 0.2)
                        )
                    ) *
                        12 <=
                0
                    ? 0
                    : profits.unlimitedYearTwo -
                      unlimitedSubscriptionCost -
                      Math.round(
                          computeMonthLease(
                              unlimitedTotalHardwareCost,
                              Math.round(unlimitedTotalHardwareCost * 0.2)
                          )
                      ) *
                          12,
            revenue:
                Math.round(unlimitedRevenueIncrease) * 11 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(dryingRevenueIncrease) * 12,
        },
        {
            name: 'Year 3',
            swCost: unlimitedSubscriptionCost,
            hwLease:
                Math.round(
                    computeMonthLease(
                        unlimitedTotalHardwareCost,
                        Math.round(unlimitedTotalHardwareCost * 0.2)
                    )
                ) * 12,
            profit:
                profits.unlimitedYearThree -
                    unlimitedSubscriptionCost -
                    Math.round(
                        computeMonthLease(
                            unlimitedTotalHardwareCost,
                            Math.round(unlimitedTotalHardwareCost * 0.2)
                        )
                    ) *
                        12 <=
                0
                    ? 0
                    : profits.unlimitedYearThree -
                      unlimitedSubscriptionCost -
                      Math.round(
                          computeMonthLease(
                              unlimitedTotalHardwareCost,
                              Math.round(unlimitedTotalHardwareCost * 0.2)
                          )
                      ) *
                          12,
            revenue:
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(unlimitedRevenueIncrease) * 12 +
                Math.round(dryingRevenueIncrease) * 12,
        },
    ];

    const [scrolledNavbar, setScrolledNavbar] = useState(false);
    const navRef = useRef();
    const tableRef = useRef();
    const nameRef = useRef(null);

    const isDesktop = useMediaQuery({
        query: '(min-width: 800px)',
    });

    function computeMonthLease(totalCost, dpCost) {
        const pv = totalCost - dpCost;
        const rate = 0.08 / 12;
        const nper = 4 * 12;
        return (pv * rate) / (1 - (1 + rate) ** -nper);
    }

    navRef.current = scrolledNavbar;

    useEffect(() => {
        const handleScroll = () => {
            if (tableRef.current) {
                const scrolled =
                    tableRef.current.getBoundingClientRect().top <= 0;
                if (navRef.current !== scrolled) {
                    setScrolledNavbar(scrolled);
                }
            }
        };
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        computeSubstrateCount();
        computeSubscriptionCost();
        calculateBaseResults();
        localStorage.setItem('sqFtCanopy', JSON.stringify(sqFtCanopy));
        localStorage.setItem(
            'eliteSubscriptionCost',
            JSON.stringify(eliteSubscriptionCost)
        );
        localStorage.setItem(
            'unlimitedSubscriptionCost',
            JSON.stringify(unlimitedSubscriptionCost)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sqFtCanopy]);
    useEffect(() => {
        computeRepeaterCount();
        calculateBaseResults();
        localStorage.setItem('gSqFtHarvest', JSON.stringify(gSqFtHarvest));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gSqFtHarvest]);
    useEffect(() => {
        computeRepeaterCount();
        calculateBaseResults();
        localStorage.setItem('wholesalePrice', JSON.stringify(wholesalePrice));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wholesalePrice]);
    useEffect(() => {
        computeRepeaterCount();
        calculateBaseResults();
        localStorage.setItem('costToProduce', JSON.stringify(costToProduce));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [costToProduce]);
    useEffect(() => {
        computeRepeaterCount();
        calculateBaseResults();
        localStorage.setItem('harvestsPerYr', JSON.stringify(harvestsPerYr));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [harvestsPerYr]);
    useEffect(() => {
        computeClimateCount();
        computeRepeaterCount();
        localStorage.setItem('propRooms', JSON.stringify(propRooms));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propRooms]);
    useEffect(() => {
        computeClimateCount();
        computeRepeaterCount();
        localStorage.setItem('vegRooms', JSON.stringify(vegRooms));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vegRooms]);
    useEffect(() => {
        computeClimateCount();
        computeRepeaterCount();
        localStorage.setItem('dryingRooms', JSON.stringify(dryingRooms));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dryingRooms]);
    useEffect(() => {
        computeClimateCount();
        computeRepeaterCount();
        localStorage.setItem('flowerRooms', JSON.stringify(flowerRooms));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowerRooms]);

    useEffect(() => {
        computeSubstrateCost();
        computeSoftwareFee();
        computeGatewayCount();
        localStorage.setItem(
            'goSubstrateCount',
            JSON.stringify(goSubstrateCount)
        );
        localStorage.setItem(
            'proSubstrateCount',
            JSON.stringify(proSubstrateCount)
        );
        localStorage.setItem(
            'eliteSubstrateCount',
            JSON.stringify(eliteSubstrateCount)
        );
        localStorage.setItem(
            'unlimitedSubstrateCount',
            JSON.stringify(unlimitedSubstrateCount)
        );
        localStorage.setItem('proSoftwareFee', JSON.stringify(proSoftwareFee));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        proSubstrateCount,
        unlimitedSubstrateCount,
        eliteSubstrateCount,
        goSubstrateCount,
    ]);
    useEffect(() => {
        computeClimateCost();
        computeSoftwareFee();
        computeGatewayCount();
        localStorage.setItem('goClimateCount', JSON.stringify(goClimateCount));
        localStorage.setItem(
            'proClimateCount',
            JSON.stringify(proClimateCount)
        );
        localStorage.setItem(
            'eliteClimateCount',
            JSON.stringify(eliteClimateCount)
        );
        localStorage.setItem(
            'unlimitedClimateCount',
            JSON.stringify(unlimitedClimateCount)
        );
        localStorage.setItem('proSoftwareFee', JSON.stringify(proSoftwareFee));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        unlimitedClimateCount,
        eliteClimateCount,
        proClimateCount,
        goClimateCount,
    ]);
    useEffect(() => {
        computeRepeaterCost();
        computeGatewayCount();
        localStorage.setItem(
            'goRepeaterCount',
            JSON.stringify(goRepeaterCount)
        );
        localStorage.setItem(
            'proRepeaterCount',
            JSON.stringify(proRepeaterCount)
        );
        localStorage.setItem(
            'eliteRepeaterCount',
            JSON.stringify(eliteRepeaterCount)
        );
        localStorage.setItem(
            'unlimitedRepeaterCount',
            JSON.stringify(unlimitedRepeaterCount)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        unlimitedRepeaterCount,
        eliteRepeaterCount,
        proRepeaterCount,
        goRepeaterCount,
    ]);
    useEffect(() => {
        computeGatewayCost();
        localStorage.setItem('goGatewayCount', JSON.stringify(goGatewayCount));
        localStorage.setItem(
            'proGatewayCount',
            JSON.stringify(proGatewayCount)
        );
        localStorage.setItem(
            'eliteGatewayCount',
            JSON.stringify(eliteGatewayCount)
        );
        localStorage.setItem(
            'unlimitedGatewayCount',
            JSON.stringify(unlimitedGatewayCount)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        unlimitedGatewayCount,
        eliteGatewayCount,
        proGatewayCount,
        goGatewayCount,
    ]);
    useEffect(() => {
        computeTotalHardwareCost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        goSubstrateCost,
        goClimateCost,
        goRepeaterCost,
        goGatewayCost,
        proSubstrateCost,
        proClimateCost,
        proRepeaterCost,
        proGatewayCost,
        eliteSubstrateCost,
        eliteClimateCost,
        eliteRepeaterCost,
        eliteGatewayCost,
        unlimitedSubstrateCost,
        unlimitedClimateCost,
        unlimitedRepeaterCost,
        unlimitedGatewayCost,
    ]);

    useEffect(() => {
        computeRevenueIncrease();
        computeDryingIntelligence();
        localStorage.setItem('baseResults', JSON.stringify(baseResults));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseResults]);

    useEffect(() => {
        computeLbsCultivationIncrease();
        localStorage.setItem(
            'goRevenueIncrease',
            JSON.stringify(goRevenueIncrease)
        );
        localStorage.setItem(
            'proRevenueIncrease',
            JSON.stringify(proRevenueIncrease)
        );
        localStorage.setItem(
            'eliteRevenueIncrease',
            JSON.stringify(eliteRevenueIncrease)
        );
        localStorage.setItem(
            'unlimitedRevenueIncrease',
            JSON.stringify(unlimitedRevenueIncrease)
        );
        localStorage.setItem(
            'goLbsCultivationIncrease',
            JSON.stringify(goLbsCultivationIncrease)
        );
        localStorage.setItem(
            'proLbsCultivationIncrease',
            JSON.stringify(proLbsCultivationIncrease)
        );
        localStorage.setItem(
            'eliteLbsCultivationIncrease',
            JSON.stringify(eliteLbsCultivationIncrease)
        );
        localStorage.setItem(
            'unlimitedLbsCultivationIncrease',
            JSON.stringify(unlimitedLbsCultivationIncrease)
        );
        localStorage.setItem(
            'dryingRevenueIncrease',
            JSON.stringify(dryingRevenueIncrease)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        goRevenueIncrease,
        proRevenueIncrease,
        eliteRevenueIncrease,
        unlimitedRevenueIncrease,
    ]);
    useEffect(() => {
        localStorage.setItem(
            'goTotalHardwareCost',
            JSON.stringify(goTotalHardwareCost)
        );
        localStorage.setItem(
            'proTotalHardwareCost',
            JSON.stringify(proTotalHardwareCost)
        );
        localStorage.setItem(
            'eliteTotalHardwareCost',
            JSON.stringify(eliteTotalHardwareCost)
        );
        localStorage.setItem(
            'unlimitedTotalHardwareCost',
            JSON.stringify(unlimitedTotalHardwareCost)
        );
    }, [
        goTotalHardwareCost,
        proTotalHardwareCost,
        eliteTotalHardwareCost,
        unlimitedTotalHardwareCost,
    ]);

    useEffect(() => {
        if (nameRef && window.navigator.onLine && unlock) {
            postscribe(
                nameRef.current,
                `<script
                    type="text/javascript"
                    src="https://go.meter.group/dcjs/304411/1354/dc.js"
                ></script>`
            );
        } else {
            navigate('/');
        }
    }, [navigate, unlock]);

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <div className={classes.demoButtonWrapperMobile}>
                    <DemoModal />
                </div>
                <div className={classes.headerContent}>
                    <div className={classes.headerContentLeft}>
                        <Typography
                            variant="h5"
                            component="h1"
                            className={classes.headerSubtitle}
                        >
                            <p id="nameRef" ref={nameRef}></p>
                        </Typography>
                        <Typography
                            variant="h4"
                            component="h1"
                            className={classes.headerTitle}
                        >
                            Based on your facility, here is the cost and
                            expected ROI for AROYA
                        </Typography>
                    </div>
                    <div className={classes.headerContentRight}>
                        <div className={classes.demoButtonWrapper}>
                            <DemoModal />
                        </div>
                        <div className={classes.headerContentRightWrapper}>
                            <div className={classes.headerProgress}>
                                <Typography>
                                    <Link
                                        to="/your-facility?unlock=true"
                                        className={classes.progressText}
                                    >
                                        Your Facility
                                    </Link>
                                </Typography>
                                <Typography>
                                    <Link
                                        to="/aroya-plans?unlock=true"
                                        className={classes.progressTextActive}
                                    >
                                        AROYA Plans
                                    </Link>
                                </Typography>
                                <Typography>
                                    <HashLink
                                        smooth
                                        to="/aroya-plans?unlock=true#plans"
                                        className={classes.progressText}
                                    >
                                        Your Plan
                                    </HashLink>
                                </Typography>
                            </div>
                            <div className={classes.headerStep}>
                                <Typography
                                    variant="h3"
                                    className={classes.stepText}
                                >
                                    02
                                </Typography>
                                <svg
                                    width="73"
                                    height="65"
                                    viewBox="0 0 73 65"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={classes.arrowRight}
                                >
                                    <path
                                        d="M0.787005 27.4489L0.787005 37.2489L54.287 37.2489L33.887 57.7489L40.887 64.6489L72.987 32.3489L40.887 0.0489213L33.887 7.04892L54.287 27.4489L0.787005 27.4489Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.headerProgressLaptop}>
                    <Typography>
                        <Link
                            to="/your-facility?unlock=true"
                            className={classes.progressText}
                        >
                            Your Facility
                        </Link>
                    </Typography>
                    <Typography>
                        <Link
                            to="/aroya-plans?unlock=true"
                            className={classes.progressTextActive}
                        >
                            AROYA Plans
                        </Link>
                    </Typography>
                    <Typography>
                        <HashLink
                            smooth
                            to="/aroya-plans?unlock=true#plans"
                            className={classes.progressText}
                        >
                            Your Plan
                        </HashLink>
                    </Typography>
                </div>
            </header>
            <main className={classes.main}>
                <section className={classes.chartContainer}>
                    <Typography variant="h3" className={classes.chartMainTitle}>
                        AROYA Unlimited
                    </Typography>
                    <div className={classes.chartWrapper}>
                        <ResponsiveContainer>
                            <BarChart
                                width={500}
                                height={500}
                                data={dataBar}
                                margin={{}}
                                className={classes.chart}
                            >
                                <XAxis
                                    dataKey="name"
                                    axisLine={false}
                                    tickLine={false}
                                    tickSize={isDesktop ? 50 : 20}
                                />
                                <YAxis hide />
                                <Tooltip
                                    formatter={(value, name, props) => [
                                        `${formatter.format(
                                            Math.round(value)
                                        )}`,
                                        name,
                                    ]}
                                    labelStyle={{
                                        fontWeight: 'bold',
                                        marginBottom: '5px',
                                    }}
                                />
                                <Legend align={isDesktop ? 'center' : 'left'} />
                                <Bar
                                    dataKey="revenue"
                                    fill="#4EA093"
                                    name="Revenue Increase"
                                    label={{
                                        children: isDesktop
                                            ? 'Revenue Increase'
                                            : '',
                                        position: 'bottom',
                                    }}
                                />
                                <Bar
                                    dataKey="profit"
                                    fill="#3A7876"
                                    name="Profit Increase"
                                    label={{
                                        children: isDesktop
                                            ? 'Profit Increase'
                                            : '',
                                        position: 'bottom',
                                    }}
                                />
                                <Bar
                                    dataKey="swCost"
                                    stackId="a"
                                    fill="#122133"
                                    name="Software Cost"
                                    label={{
                                        children: isDesktop ? 'Cost' : '',
                                        position: 'bottom',
                                    }}
                                />
                                <Bar
                                    dataKey="hwDp"
                                    stackId="a"
                                    fill="#1D3C49"
                                    name="Hardware Down Payment"
                                />
                                <Bar
                                    dataKey="hwLease"
                                    stackId="a"
                                    fill="#2A585D"
                                    name="Hardware Lease"
                                />
                            </BarChart>
                        </ResponsiveContainer>
                        {/* <div className={classes.chartText}>
                            <Typography
                                variant="h3"
                                className={classes.chartTitle}
                            >
                                WHY NOW?
                            </Typography>
                            <Typography className={classes.chartContent}>
                                Every month without <b>AROYA</b> is&nbsp;
                                <b>
                                    {formatter.format(
                                        Math.round(unlimitedRevenueIncrease)
                                    )}
                                </b>
                                &nbsp;in lost profit
                            </Typography>
                        </div> */}
                    </div>
                </section>
                <section className={classes.plansContainerTabs}>
                    <TableContainer className={classes.plansContainerTab}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell
                                        className={clsx(
                                            classes.tableCell,
                                            classes.hasNoBorder,
                                            classes.bgBlack,
                                            classes.mostPopularCell
                                        )}
                                        align="center"
                                    >
                                        <Typography
                                            variant="h6"
                                            className={classes.mostPopular}
                                        >
                                            Most Popular 🔥
                                        </Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                </section>
                <section className={classes.plansContainer} id="plans">
                    <TableContainer
                        className={
                            scrolledNavbar
                                ? classes.tableContainerDupe
                                : classes.tableContainerScrolled
                        }
                    >
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        className={clsx(
                                            classes.tableCell,
                                            classes.hasNoBorder
                                        )}
                                    >
                                        <Typography
                                            variant="h3"
                                            className={classes.planTableTitle}
                                        >
                                            PLANS
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        className={clsx(
                                            classes.tableCell,
                                            classes.hasNoBorder
                                        )}
                                        align="center"
                                    >
                                        <Typography
                                            variant="h6"
                                            className={classes.planTitle}
                                        >
                                            AROYA GO
                                        </Typography>
                                        <Link
                                            to="/your-plan?unlock=true&plan=GO"
                                            state={{ plan: 'GO' }}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                disableElevation
                                                className={classes.planButton}
                                            >
                                                Select
                                            </Button>
                                        </Link>
                                    </TableCell>
                                    <TableCell
                                        className={clsx(
                                            classes.tableCell,
                                            classes.hasNoBorder
                                        )}
                                        align="center"
                                    >
                                        <Typography
                                            variant="h6"
                                            className={classes.planTitle}
                                        >
                                            AROYA PRO
                                        </Typography>
                                        <Link
                                            to="/your-plan?unlock=true&plan=PRO"
                                            state={{ plan: 'PRO' }}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                disableElevation
                                                className={classes.planButton}
                                            >
                                                Select
                                            </Button>
                                        </Link>
                                    </TableCell>
                                    <TableCell
                                        className={clsx(
                                            classes.tableCell,
                                            classes.hasNoBorder,
                                            classes.bgGreen
                                        )}
                                        align="center"
                                    >
                                        <Typography
                                            variant="h6"
                                            className={classes.planTitle}
                                        >
                                            AROYA Elite
                                        </Typography>
                                        <Link
                                            to="/your-plan?unlock=true&plan=Elite"
                                            state={{ plan: 'Elite' }}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                disableElevation
                                                className={classes.planButton}
                                            >
                                                Select
                                            </Button>
                                        </Link>
                                    </TableCell>
                                    <TableCell
                                        className={clsx(
                                            classes.tableCell,
                                            classes.hasNoBorder
                                        )}
                                        align="center"
                                    >
                                        <Typography
                                            variant="h6"
                                            className={classes.planTitle}
                                        >
                                            AROYA Unlimited
                                        </Typography>
                                        <Link
                                            to="/your-plan?unlock=true&plan=Unlimited"
                                            state={{ plan: 'Unlimited' }}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                disableElevation
                                                className={classes.planButton}
                                            >
                                                Select
                                            </Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    <div className={classes.plansTable}>
                        <TableContainer className={classes.tableContainer}>
                            <Table>
                                <TableHead
                                    className={classes.dupeTable}
                                    ref={tableRef}
                                >
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                        >
                                            <Typography
                                                variant="h3"
                                                className={
                                                    classes.planTableTitle
                                                }
                                            >
                                                PLANS
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Typography
                                                variant="h6"
                                                className={classes.planTitle}
                                            >
                                                AROYA GO
                                            </Typography>
                                            <Link
                                                to="/your-plan?unlock=true&plan=GO"
                                                state={{ plan: 'GO' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Typography
                                                variant="h6"
                                                className={classes.planTitle}
                                            >
                                                AROYA PRO
                                            </Typography>
                                            <Link
                                                to="/your-plan?unlock=true&plan=PRO"
                                                state={{ plan: 'PRO' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Typography
                                                variant="h6"
                                                className={classes.planTitle}
                                            >
                                                AROYA Elite
                                            </Typography>
                                            <Link
                                                to="/your-plan?unlock=true&plan=Elite"
                                                state={{ plan: 'Elite' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Typography
                                                variant="h6"
                                                className={classes.planTitle}
                                            >
                                                AROYA Unlimited
                                            </Typography>
                                            <Link
                                                to="/your-plan?unlock=true&plan=Unlimited"
                                                state={{ plan: 'Unlimited' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Yield increases start in
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            6 Months
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            5 Months
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            4 Months
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            3 Months
                                        </TableCell>
                                    </TableRow>
                                    {/* <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Yield increase each month
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            {goLbsCultivation} lbs
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            {proLbsCultivation} lbs
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            {eliteLbsCultivation} lbs
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            {unlimitedLbsCultivation} lbs
                                        </TableCell>
                                    </TableRow> */}
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Profit increase year 1
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div
                                                className={clsx(
                                                    classes.tableHasBr,
                                                    classes.contactSalesFont
                                                )}
                                            >
                                                Contact Sales
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    2% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {formatter.format(
                                                    Math.round(
                                                        profits.proYearOne -
                                                            proSoftwareFee -
                                                            Math.round(
                                                                proTotalHardwareCost *
                                                                    0.2
                                                            ) -
                                                            Math.round(
                                                                computeMonthLease(
                                                                    proTotalHardwareCost,
                                                                    Math.round(
                                                                        proTotalHardwareCost *
                                                                            0.2
                                                                    )
                                                                )
                                                            ) *
                                                                12 <=
                                                            0
                                                            ? 0
                                                            : profits.proYearOne -
                                                                  proSoftwareFee -
                                                                  Math.round(
                                                                      proTotalHardwareCost *
                                                                          0.2
                                                                  ) -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          proTotalHardwareCost,
                                                                          Math.round(
                                                                              proTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                    )
                                                )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    5% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {formatter.format(
                                                    Math.round(
                                                        profits.eliteYearOne -
                                                            eliteSubscriptionCost -
                                                            Math.round(
                                                                eliteTotalHardwareCost *
                                                                    0.2
                                                            ) -
                                                            Math.round(
                                                                computeMonthLease(
                                                                    eliteTotalHardwareCost,
                                                                    Math.round(
                                                                        eliteTotalHardwareCost *
                                                                            0.2
                                                                    )
                                                                )
                                                            ) *
                                                                12 <=
                                                            0
                                                            ? 0
                                                            : profits.eliteYearOne -
                                                                  eliteSubscriptionCost -
                                                                  Math.round(
                                                                      eliteTotalHardwareCost *
                                                                          0.2
                                                                  ) -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          eliteTotalHardwareCost,
                                                                          Math.round(
                                                                              eliteTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                    )
                                                )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    10% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {formatter.format(
                                                    Math.round(
                                                        profits.unlimitedYearOne -
                                                            unlimitedSubscriptionCost -
                                                            Math.round(
                                                                unlimitedTotalHardwareCost *
                                                                    0.2
                                                            ) -
                                                            Math.round(
                                                                computeMonthLease(
                                                                    unlimitedTotalHardwareCost,
                                                                    Math.round(
                                                                        unlimitedTotalHardwareCost *
                                                                            0.2
                                                                    )
                                                                )
                                                            ) *
                                                                12 <=
                                                            0
                                                            ? 0
                                                            : profits.unlimitedYearOne -
                                                                  unlimitedSubscriptionCost -
                                                                  Math.round(
                                                                      unlimitedTotalHardwareCost *
                                                                          0.2
                                                                  ) -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          unlimitedTotalHardwareCost,
                                                                          Math.round(
                                                                              unlimitedTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                    )
                                                )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    15% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Profit increase year 2
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div
                                                className={clsx(
                                                    classes.tableHasBr,
                                                    classes.contactSalesFont
                                                )}
                                            >
                                                Contact Sales
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    2% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {formatter.format(
                                                    Math.round(
                                                        profits.proYearTwo -
                                                            proSoftwareFee -
                                                            Math.round(
                                                                computeMonthLease(
                                                                    proTotalHardwareCost,
                                                                    Math.round(
                                                                        proTotalHardwareCost *
                                                                            0.2
                                                                    )
                                                                )
                                                            ) *
                                                                12 <=
                                                            0
                                                            ? 0
                                                            : profits.proYearTwo -
                                                                  proSoftwareFee -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          proTotalHardwareCost,
                                                                          Math.round(
                                                                              proTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                    )
                                                )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    5% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {formatter.format(
                                                    Math.round(
                                                        profits.eliteYearTwo -
                                                            eliteSubscriptionCost -
                                                            Math.round(
                                                                computeMonthLease(
                                                                    eliteTotalHardwareCost,
                                                                    Math.round(
                                                                        eliteTotalHardwareCost *
                                                                            0.2
                                                                    )
                                                                )
                                                            ) *
                                                                12 <=
                                                            0
                                                            ? 0
                                                            : profits.eliteYearTwo -
                                                                  eliteSubscriptionCost -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          eliteTotalHardwareCost,
                                                                          Math.round(
                                                                              eliteTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                    )
                                                )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    10% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {formatter.format(
                                                    Math.round(
                                                        profits.unlimitedYearTwo -
                                                            unlimitedSubscriptionCost -
                                                            Math.round(
                                                                computeMonthLease(
                                                                    unlimitedTotalHardwareCost,
                                                                    Math.round(
                                                                        unlimitedTotalHardwareCost *
                                                                            0.2
                                                                    )
                                                                )
                                                            ) *
                                                                12 <=
                                                            0
                                                            ? 0
                                                            : profits.unlimitedYearTwo -
                                                                  unlimitedSubscriptionCost -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          unlimitedTotalHardwareCost,
                                                                          Math.round(
                                                                              unlimitedTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                    )
                                                )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    15% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className={classes.featuresTable}>
                        <TableContainer className={classes.tableContainer}>
                            <Table sx={{ minWidth: 650 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            component="th"
                                            scope="row"
                                            colSpan={5}
                                        >
                                            <Typography
                                                variant="h6"
                                                className={
                                                    classes.productFeatures
                                                }
                                            >
                                                Product Features
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Users
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Unlimited
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Unlimited
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Unlimited
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            METRC Integration
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Yes
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Yes
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Analytics
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Yes
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Yes
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Customer success calls
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Monthly
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Weekly
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Guaranteed Yield Increase*
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            10%
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Cost per sensor
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.table
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span> -
                                                <br />
                                                <span>Climate</span> -
                                                <br />
                                                <span>Repeater</span> -
                                                <br />
                                                <span>Gateway</span> -
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span> $538
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Climate</span> $538
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Repeater</span> $305
                                                <br />
                                                <span>Gateway</span> $538
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span> $414
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Climate</span> $414
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Repeater</span> $254
                                                <br />
                                                <span>Gateway</span> $414
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span> $414
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Climate</span> $414
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Repeater</span> $254
                                                <br />
                                                <span>Gateway</span> $414
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className={classes.costsTable}>
                        <TableContainer className={classes.tableContainer}>
                            <Table sx={{ minWidth: 650 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Recommended sensors
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span> -
                                                <br />
                                                <span>Climate</span> -
                                                <br />
                                                <span>Repeater</span> -
                                                <br />
                                                <span>Gateway</span> -
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span>{' '}
                                                {proSubstrateCount}
                                                <br />
                                                <span>Climate</span>{' '}
                                                {proClimateCount}
                                                <br />
                                                <span>Repeater</span>{' '}
                                                {proRepeaterCount}
                                                <br />
                                                <span>Gateway</span>{' '}
                                                {proGatewayCount}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span>{' '}
                                                {eliteSubstrateCount}
                                                <br />
                                                <span>Climate</span>{' '}
                                                {eliteClimateCount}
                                                <br />
                                                <span>Repeater</span>{' '}
                                                {eliteRepeaterCount}
                                                <br />
                                                <span>Gateway</span>{' '}
                                                {eliteGatewayCount}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span>{' '}
                                                {unlimitedSubstrateCount}
                                                <br />
                                                <span>Climate</span>{' '}
                                                {unlimitedClimateCount}
                                                <br />
                                                <span>Repeater</span>{' '}
                                                {unlimitedRepeaterCount}
                                                <br />
                                                <span>Gateway</span>{' '}
                                                {unlimitedGatewayCount}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Year 1 down payment
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentMed
                                            )}
                                            align="center"
                                        >
                                            Call for pricing
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    proTotalHardwareCost * 0.2
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    eliteTotalHardwareCost * 0.2
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    unlimitedTotalHardwareCost *
                                                        0.2
                                                )
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Monthly hardware lease
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentMed
                                            )}
                                            align="center"
                                        >
                                            Call for pricing
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    computeMonthLease(
                                                        proTotalHardwareCost,
                                                        Math.round(
                                                            proTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    computeMonthLease(
                                                        eliteTotalHardwareCost,
                                                        Math.round(
                                                            eliteTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    computeMonthLease(
                                                        unlimitedTotalHardwareCost,
                                                        Math.round(
                                                            unlimitedTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                )
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Yearly subscription
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            $0
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(proSoftwareFee)}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                eliteSubscriptionCost
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                unlimitedSubscriptionCost
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className={classes.selectsTable}>
                        <TableContainer className={classes.tableContainer}>
                            <Table sx={{ minWidth: 650 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        ></TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Link
                                                to="/your-plan?unlock=true&plan=GO"
                                                state={{ plan: 'GO' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Link
                                                to="/your-plan?unlock=true&plan=PRO"
                                                state={{ plan: 'PRO' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Link
                                                to="/your-plan?unlock=true&plan=Elite"
                                                state={{ plan: 'Elite' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Link
                                                to="/your-plan?unlock=true&plan=Unlimited"
                                                state={{ plan: 'Unlimited' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className={classes.plansTableMobile}>
                        <TableContainer className={classes.tableContainer}>
                            <Table>
                                <TableHead className={classes.dupeTable}>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                        >
                                            <Typography
                                                variant="h3"
                                                className={
                                                    classes.planTableTitle
                                                }
                                            >
                                                PLANS
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Typography
                                                variant="h6"
                                                className={classes.planTitle}
                                            >
                                                AROYA GO
                                            </Typography>
                                            <Link
                                                to="/your-plan?unlock=true&plan=GO"
                                                state={{ plan: 'GO' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Typography
                                                variant="h6"
                                                className={classes.planTitle}
                                            >
                                                AROYA PRO
                                            </Typography>
                                            <Link
                                                to="/your-plan?unlock=true&plan=PRO"
                                                state={{ plan: 'PRO' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Typography
                                                variant="h6"
                                                className={classes.planTitle}
                                            >
                                                AROYA Elite
                                            </Typography>
                                            <Link
                                                to="/your-plan?unlock=true&plan=Elite"
                                                state={{ plan: 'Elite' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Typography
                                                variant="h6"
                                                className={classes.planTitle}
                                            >
                                                AROYA Unlimited
                                            </Typography>
                                            <Link
                                                to="/your-plan?unlock=true&plan=Unlimited"
                                                state={{ plan: 'Unlimited' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Yield increases start in
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            6 Months
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            5 Months
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            4 Months
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            3 Months
                                        </TableCell>
                                    </TableRow>
                                    {/* <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Yield increase each month
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            {goLbsCultivation} lbs
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            {proLbsCultivation} lbs
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            {eliteLbsCultivation} lbs
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            {unlimitedLbsCultivation} lbs
                                        </TableCell>
                                    </TableRow> */}
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Profit Increase Year 1
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                Contact Sales
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    2% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {profits.proYearOne -
                                                    proSoftwareFee -
                                                    Math.round(
                                                        proTotalHardwareCost *
                                                            0.2
                                                    ) -
                                                    Math.round(
                                                        computeMonthLease(
                                                            proTotalHardwareCost,
                                                            Math.round(
                                                                proTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                0
                                                    ? 0
                                                    : formatter.format(
                                                          Math.round(
                                                              profits.proYearOne -
                                                                  proSoftwareFee -
                                                                  Math.round(
                                                                      proTotalHardwareCost *
                                                                          0.2
                                                                  ) -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          proTotalHardwareCost,
                                                                          Math.round(
                                                                              proTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                          )
                                                      )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    5% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {profits.eliteYearOne -
                                                    eliteSubscriptionCost -
                                                    Math.round(
                                                        eliteTotalHardwareCost *
                                                            0.2
                                                    ) -
                                                    Math.round(
                                                        computeMonthLease(
                                                            eliteTotalHardwareCost,
                                                            Math.round(
                                                                eliteTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                0
                                                    ? 0
                                                    : formatter.format(
                                                          Math.round(
                                                              profits.eliteYearOne -
                                                                  eliteSubscriptionCost -
                                                                  Math.round(
                                                                      eliteTotalHardwareCost *
                                                                          0.2
                                                                  ) -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          eliteTotalHardwareCost,
                                                                          Math.round(
                                                                              eliteTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                          )
                                                      )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    10% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {profits.unlimitedYearOne -
                                                    unlimitedSubscriptionCost -
                                                    Math.round(
                                                        unlimitedTotalHardwareCost *
                                                            0.2
                                                    ) -
                                                    Math.round(
                                                        computeMonthLease(
                                                            unlimitedTotalHardwareCost,
                                                            Math.round(
                                                                unlimitedTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                0
                                                    ? 0
                                                    : formatter.format(
                                                          Math.round(
                                                              profits.unlimitedYearOne -
                                                                  unlimitedSubscriptionCost -
                                                                  Math.round(
                                                                      unlimitedTotalHardwareCost *
                                                                          0.2
                                                                  ) -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          unlimitedTotalHardwareCost,
                                                                          Math.round(
                                                                              unlimitedTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                          )
                                                      )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    15% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Profit increase year 2
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                Contact Sales
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    2% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {profits.proYearTwo -
                                                    proSoftwareFee -
                                                    Math.round(
                                                        computeMonthLease(
                                                            proTotalHardwareCost,
                                                            Math.round(
                                                                proTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                0
                                                    ? 0
                                                    : formatter.format(
                                                          Math.round(
                                                              profits.proYearTwo -
                                                                  proSoftwareFee -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          proTotalHardwareCost,
                                                                          Math.round(
                                                                              proTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                          )
                                                      )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    5% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {profits.eliteYearTwo -
                                                    eliteSubscriptionCost -
                                                    Math.round(
                                                        computeMonthLease(
                                                            eliteTotalHardwareCost,
                                                            Math.round(
                                                                eliteTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                0
                                                    ? 0
                                                    : formatter.format(
                                                          Math.round(
                                                              profits.eliteYearTwo -
                                                                  eliteSubscriptionCost -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          eliteTotalHardwareCost,
                                                                          Math.round(
                                                                              eliteTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                          )
                                                      )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    10% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentBig
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                {profits.unlimitedYearTwo -
                                                    unlimitedSubscriptionCost -
                                                    Math.round(
                                                        computeMonthLease(
                                                            unlimitedTotalHardwareCost,
                                                            Math.round(
                                                                unlimitedTotalHardwareCost *
                                                                    0.2
                                                            )
                                                        )
                                                    ) *
                                                        12 <=
                                                0
                                                    ? 0
                                                    : formatter.format(
                                                          Math.round(
                                                              profits.unlimitedYearTwo -
                                                                  unlimitedSubscriptionCost -
                                                                  Math.round(
                                                                      computeMonthLease(
                                                                          unlimitedTotalHardwareCost,
                                                                          Math.round(
                                                                              unlimitedTotalHardwareCost *
                                                                                  0.2
                                                                          )
                                                                      )
                                                                  ) *
                                                                      12
                                                          )
                                                      )}
                                                <br />
                                                <span
                                                    className={
                                                        classes.tableContentSmall
                                                    }
                                                >
                                                    15% Increase
                                                </span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            component="th"
                                            scope="row"
                                            colSpan={5}
                                        >
                                            <Typography
                                                variant="h6"
                                                className={
                                                    classes.productFeatures
                                                }
                                            >
                                                Product Features
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Users
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Unlimited
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Unlimited
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Unlimited
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            METRC Integration
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Yes
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Yes
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Analytics
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Yes
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Yes
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Customer success calls
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Monthly
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            Weekly
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Guaranteed Yield Increase*
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent,
                                                classes.tableNo
                                            )}
                                            align="center"
                                        >
                                            No
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableContent
                                            )}
                                            align="center"
                                        >
                                            10%
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Cost per sensor
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.table
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span> -
                                                <br />
                                                <span>Climate</span> -
                                                <br />
                                                <span>Repeater</span> -
                                                <br />
                                                <span>Gateway</span> -
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span> $538
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Climate</span> $538
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Repeater</span> $305
                                                <br />
                                                <span>Gateway</span> $538
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span> $414
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Climate</span> $414
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Repeater</span> $254
                                                <br />
                                                <span>Gateway</span> $414
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span> $414
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Climate</span> $414
                                                <div
                                                    className={
                                                        classes.dataTransmission
                                                    }
                                                >
                                                    Data Transmission Included
                                                </div>
                                                <span>Repeater</span> $254
                                                <br />
                                                <span>Gateway</span> $414
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Recommended sensors
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span> -
                                                <br />
                                                <span>Climate</span> -
                                                <br />
                                                <span>Repeater</span> -
                                                <br />
                                                <span>Gateway</span> -
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span>{' '}
                                                {proSubstrateCount}
                                                <br />
                                                <span>Climate</span>{' '}
                                                {proClimateCount}
                                                <br />
                                                <span>Repeater</span>{' '}
                                                {proRepeaterCount}
                                                <br />
                                                <span>Gateway</span>{' '}
                                                {proGatewayCount}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span>{' '}
                                                {eliteSubstrateCount}
                                                <br />
                                                <span>Climate</span>{' '}
                                                {eliteClimateCount}
                                                <br />
                                                <span>Repeater</span>{' '}
                                                {eliteRepeaterCount}
                                                <br />
                                                <span>Gateway</span>{' '}
                                                {eliteGatewayCount}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <div className={classes.tableHasBr}>
                                                <span>Substrate</span>{' '}
                                                {unlimitedSubstrateCount}
                                                <br />
                                                <span>Climate</span>{' '}
                                                {unlimitedClimateCount}
                                                <br />
                                                <span>Repeater</span>{' '}
                                                {unlimitedRepeaterCount}
                                                <br />
                                                <span>Gateway</span>{' '}
                                                {unlimitedGatewayCount}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Year 1 down payment
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentMed
                                            )}
                                            align="center"
                                        >
                                            Call for pricing
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    proTotalHardwareCost * 0.2
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    eliteTotalHardwareCost * 0.2
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    unlimitedTotalHardwareCost *
                                                        0.2
                                                )
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Monthly hardware lease
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentMed
                                            )}
                                            align="center"
                                        >
                                            Call for pricing
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    computeMonthLease(
                                                        proTotalHardwareCost,
                                                        Math.round(
                                                            proTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    computeMonthLease(
                                                        eliteTotalHardwareCost,
                                                        Math.round(
                                                            eliteTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                Math.round(
                                                    computeMonthLease(
                                                        unlimitedTotalHardwareCost,
                                                        Math.round(
                                                            unlimitedTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                )
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableTitle
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            Yearly subscription
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            $0
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(proSoftwareFee)}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                eliteSubscriptionCost
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder,
                                                classes.tableContent,
                                                classes.tableContentNormal
                                            )}
                                            align="center"
                                        >
                                            {formatter.format(
                                                unlimitedSubscriptionCost
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            component="th"
                                            scope="row"
                                            align="right"
                                        ></TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Link
                                                to="/your-plan?unlock=true&plan=GO"
                                                state={{ plan: 'GO' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Link
                                                to="/your-plan?unlock=true&plan=PRO"
                                                state={{ plan: 'PRO' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Link
                                                to="/your-plan?unlock=true&plan=Elite"
                                                state={{ plan: 'Elite' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.hasNoBorder
                                            )}
                                            align="center"
                                        >
                                            <Link
                                                to="/your-plan?unlock=true&plan=Unlimited"
                                                state={{ plan: 'Unlimited' }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disableElevation
                                                    className={
                                                        classes.planButton
                                                    }
                                                >
                                                    Select
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </section>
            </main>
            <footer className={classes.footer}>
                <div className={classes.footerLeft}>
                    <Link
                        to="/your-facility?unlock=true"
                        className={classes.editLink}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            disableElevation
                            className={classes.editButton}
                        >
                            Edit
                        </Button>
                    </Link>
                </div>
                <div className={classes.footerRight}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.downloadButton}
                    >
                        <PDFDownloadLink
                            document={
                                <PdfDoc
                                    goRevenueIncrease={goRevenueIncrease}
                                    proRevenueIncrease={proRevenueIncrease}
                                    eliteRevenueIncrease={eliteRevenueIncrease}
                                    unlimitedRevenueIncrease={
                                        unlimitedRevenueIncrease
                                    }
                                    goLbsCultivation={goLbsCultivation}
                                    proLbsCultivation={proLbsCultivation}
                                    eliteLbsCultivation={eliteLbsCultivation}
                                    unlimitedLbsCultivation={
                                        unlimitedLbsCultivation
                                    }
                                    goSubstrateCount={goSubstrateCount}
                                    proSubstrateCount={proSubstrateCount}
                                    eliteSubstrateCount={eliteSubstrateCount}
                                    unlimitedSubstrateCount={
                                        unlimitedSubstrateCount
                                    }
                                    goClimateCount={goClimateCount}
                                    proClimateCount={proClimateCount}
                                    eliteClimateCount={eliteClimateCount}
                                    unlimitedClimateCount={
                                        unlimitedClimateCount
                                    }
                                    goRepeaterCount={goRepeaterCount}
                                    proRepeaterCount={proRepeaterCount}
                                    eliteRepeaterCount={eliteRepeaterCount}
                                    unlimitedRepeaterCount={
                                        unlimitedRepeaterCount
                                    }
                                    goGatewayCount={goGatewayCount}
                                    proGatewayCount={proGatewayCount}
                                    eliteGatewayCount={eliteGatewayCount}
                                    unlimitedGatewayCount={
                                        unlimitedGatewayCount
                                    }
                                    goTotalHardwareCost={goTotalHardwareCost}
                                    proTotalHardwareCost={proTotalHardwareCost}
                                    eliteTotalHardwareCost={
                                        eliteTotalHardwareCost
                                    }
                                    unlimitedTotalHardwareCost={
                                        unlimitedTotalHardwareCost
                                    }
                                    proSoftwareFee={proSoftwareFee}
                                    eliteSubscriptionCost={
                                        eliteSubscriptionCost
                                    }
                                    unlimitedSubscriptionCost={
                                        unlimitedSubscriptionCost
                                    }
                                    baseResults={baseResults}
                                    costToProduce={costToProduce}
                                    wholesalePrice={wholesalePrice}
                                />
                            }
                            fileName={`AROYA Plans.pdf`}
                        >
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading...' : 'Download PDF'
                            }
                        </PDFDownloadLink>
                    </Button>
                </div>
            </footer>
        </div>
    );
};

export default AroyaPlans;
