import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import YourFacility from './pages/YourFacility';
import AroyaPlans from './pages/AroyaPlans';
import YourPlan from './pages/YourPlan';
import Homepage from './pages/Homepage';
import './App.css';
import ScrollToTop from './components/ScrollToTop';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.primary.main,
        padding: '3rem',
        maxWidth: '1600px',
        margin: '0 auto',
        [theme.breakpoints.down('lg')]: {
            padding: '2rem',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
        },
    },
}));

function App() {
    const classes = useStyles();

    return (
        <Router>
            <ScrollToTop>
                <div className={classes.root}>
                    <Routes>
                        <Route index element={<Homepage />} />
                        <Route
                            path="/your-facility"
                            element={<YourFacility />}
                        />
                        <Route path="/aroya-plans" element={<AroyaPlans />} />
                        <Route path="/your-plan" element={<YourPlan />} />
                        <Route
                            path="*"
                            element={<p>There's nothing here: 404! </p>}
                        />
                    </Routes>
                </div>
            </ScrollToTop>
        </Router>
    );
}

export default App;
