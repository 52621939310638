import React, { useState } from 'react';
import {
    makeStyles,
    Modal,
    Backdrop,
    Fade,
    Box,
    Typography,
    Button,
    IconButton,
} from '@material-ui/core';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    root: {},
    demoButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    modal: {
        '& .MuiBackdrop-root': {
            backdropFilter: 'blur(3px)',
        },
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        background: '#fff',
        boxShadow: '0 5px 10px rgb(0 0 0 / 30%)',
        padding: '2rem',
        borderRadius: '5px',
    },
    closeButton: {
        padding: 0,
        position: 'absolute',
        top: '20px',
        right: '20px',
    },
    modalTitle: {
        marginBottom: '10px',
    },
    modalBody: {
        color: '#787878',
        marginBottom: '10px',
        '& a': {
            textDecoration: 'underline',
        },
    },
}));

const DemoModal = () => {
    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                disableElevation
                className={classes.demoButton}
                onClick={handleOpen}
            >
                GET A DEMO
            </Button>
            <Modal
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
                className={classes.modal}
            >
                <Fade in={openModal}>
                    <Box className={classes.modalBox}>
                        <IconButton
                            aria-label="close"
                            disableRipple
                            disableFocusRipple
                            className={classes.closeButton}
                            onClick={handleClose}
                        >
                            <Close />
                        </IconButton>
                        <Typography
                            variant="h5"
                            component="h2"
                            className={classes.modalTitle}
                        >
                            Ready to grow?
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.modalBody}
                        >
                            See how AROYA helps commercial growers cultivate
                            higher yields.
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.modalBody}
                        >
                            Fill out the form below or email{' '}
                            <a href="mailto:sales.aroya@metergroup.com">
                                sales.aroya@metergroup.com
                            </a>{' '}
                            to request a quote.
                        </Typography>
                        <iframe
                            title="quote-form"
                            loading="lazy"
                            data-src="https://go.meter.group/l/304411/2019-10-16/h3v24?fieldLabelBg=ffffff"
                            width="100%"
                            height="500px"
                            type="text/html"
                            frameBorder="0"
                            allowtransparency="true"
                            rel="dns-prefetch"
                            src="https://go.meter.group/l/304411/2019-10-16/h3v24?fieldLabelBg=ffffff&amp;Special_Tracking=AROYA%20-%20Home"
                        ></iframe>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default DemoModal;
