import React from 'react';
import {
    Page,
    Text,
    Svg,
    Path,
    View,
    Document,
    StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
    },
    frame: {
        margin: 40,
        flexGrow: 1,
    },
    header: {
        marginBottom: 20,
    },
    headerRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    introTitle: {
        fontWeight: 'bold',
        fontSize: '20px',
        paddingtop: '30px',
        paddingLeft: '65px',
        marginBottom: '30px',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '5px 0',
    },
    logo: {
        position: 'absolute',
        top: '25px',
        left: '40px',
    },
    inputsTable: {
        width: '100%',
        marginBottom: '20px',
    },
    inputsTitle: {
        fontSize: '12px',
        color: '#6a6a6a',
    },
    inputsData: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    outputTable: {
        width: '100%',
        marginBottom: '20px',
    },
    outputTitle: {
        fontSize: '12px',
        color: '#6a6a6a',
    },
    outputData: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    featuresTable: {
        width: '100%',
        marginBottom: '50px',
    },
    tableHeading: {
        fontSize: '14px',
        fontWeight: 'bold',
        paddingBottom: '8px',
        marginBottom: '3px',
        borderBottom: '1px solid #6a6a6a',
    },
    featuresTitle: {
        fontSize: '12px',
        color: '#6a6a6a',
    },
    featuresData: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    featuresDataNo: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#bbb',
    },
    footerText: {
        fontStyle: 'italic',
        fontSize: '9px',
    },
});

const PdfDocPlan = ({
    goRevenueIncrease,
    proRevenueIncrease,
    eliteRevenueIncrease,
    unlimitedRevenueIncrease,
    goLbsCultivation,
    proLbsCultivation,
    eliteLbsCultivation,
    unlimitedLbsCultivation,
    goTotalHardwareCost,
    proTotalHardwareCost,
    eliteTotalHardwareCost,
    unlimitedTotalHardwareCost,
    proSoftwareFee,
    eliteSubscriptionCost,
    unlimitedSubscriptionCost,
    plan,
    sqFtCanopy,
    gSqFtHarvest,
    wholesalePrice,
    costToProduce,
    harvestsPerYr,
    propRooms,
    vegRooms,
    flowerRooms,
    dryingRooms,
    baseResults,
}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    });

    function computeMonthLease(totalCost, dpCost) {
        const pv = totalCost - dpCost;
        const rate = 0.08 / 12;
        const nper = 4 * 12;
        return (pv * rate) / (1 - (1 + rate) ** -nper);
    }

    let profits = {
        goYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation +
                ((baseResults.modelRevenue + goRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation +
                    ((baseResults.modelRevenue + goRevenueIncrease) * 0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation +
                        ((baseResults.modelRevenue + goRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 2 +
                ((baseResults.modelRevenue + goRevenueIncrease * 2) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 2 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 2 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 3 +
                ((baseResults.modelRevenue + goRevenueIncrease * 3) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 3 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 3 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 4 +
                ((baseResults.modelRevenue + goRevenueIncrease * 4) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 4 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 4 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 5 +
                ((baseResults.modelRevenue + goRevenueIncrease * 5) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 5 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 5 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 6 +
                ((baseResults.modelRevenue + goRevenueIncrease * 6) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 6 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 6 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 7 +
                ((baseResults.modelRevenue + goRevenueIncrease * 7) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 7 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 7 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        goYearTwo:
            (baseResults.lbsProduced +
                goLbsCultivation * 8 +
                ((baseResults.modelRevenue + goRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                goLbsCultivation * 8 +
                ((baseResults.modelRevenue + goRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    goLbsCultivation * 8 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    goLbsCultivation * 8 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                goLbsCultivation * 9 +
                ((baseResults.modelRevenue + goRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 9 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 9 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 9 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 9) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 10 +
                ((baseResults.modelRevenue + goRevenueIncrease * 10) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 10 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 10 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 10 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 10) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 11 +
                ((baseResults.modelRevenue + goRevenueIncrease * 11) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 11 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 11 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 11 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 11) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                goLbsCultivation * 12 +
                ((baseResults.modelRevenue + goRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    goLbsCultivation * 12 +
                    ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        goLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        goLbsCultivation * 12 +
                        ((baseResults.modelRevenue + goRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        proYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation +
                ((baseResults.modelRevenue + proRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation +
                    ((baseResults.modelRevenue + proRevenueIncrease) * 0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation +
                        ((baseResults.modelRevenue + proRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 2 +
                ((baseResults.modelRevenue + proRevenueIncrease * 2) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 2 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 2 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 3 +
                ((baseResults.modelRevenue + proRevenueIncrease * 3) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 3 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 3 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 4 +
                ((baseResults.modelRevenue + proRevenueIncrease * 4) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 4 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 4 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 5 +
                ((baseResults.modelRevenue + proRevenueIncrease * 5) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 5 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 5 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 6 +
                ((baseResults.modelRevenue + proRevenueIncrease * 6) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 6 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 6 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 7 +
                ((baseResults.modelRevenue + proRevenueIncrease * 7) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 7 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 7 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 8 +
                ((baseResults.modelRevenue + proRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 8 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 8 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 8 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 8) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        proYearTwo:
            (baseResults.lbsProduced +
                proLbsCultivation * 9 +
                ((baseResults.modelRevenue + proRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                proLbsCultivation * 9 +
                ((baseResults.modelRevenue + proRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    proLbsCultivation * 9 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    proLbsCultivation * 9 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                proLbsCultivation * 10 +
                ((baseResults.modelRevenue + proRevenueIncrease * 10) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 10 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 10 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 10 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 10) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 11 +
                ((baseResults.modelRevenue + proRevenueIncrease * 11) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 11 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 11 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 11 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 11) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                proLbsCultivation * 12 +
                ((baseResults.modelRevenue + proRevenueIncrease * 12) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    proLbsCultivation * 12 +
                    ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        proLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        proLbsCultivation * 12 +
                        ((baseResults.modelRevenue + proRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        eliteYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation +
                ((baseResults.modelRevenue + eliteRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation +
                    ((baseResults.modelRevenue + eliteRevenueIncrease) * 0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation +
                        ((baseResults.modelRevenue + eliteRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 2 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 2) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 2 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 2 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 3 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 3) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 3 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 3 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 4 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 4) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 4 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 4 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 5 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 5) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 5 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 5 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 6 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 6) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 6 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 6 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 7 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 7) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 7 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 7 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 8 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 8) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 8 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 8 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 8 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 8) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 9 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 9) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 9 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 9 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 9 +
                        ((baseResults.modelRevenue + eliteRevenueIncrease * 9) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        eliteYearTwo:
            (baseResults.lbsProduced +
                eliteLbsCultivation * 10 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 10) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 10 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 10) *
                    0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    eliteLbsCultivation * 10 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    eliteLbsCultivation * 10 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 11 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 11) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 11 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 11 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 11 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 11) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                eliteLbsCultivation * 12 +
                ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    eliteLbsCultivation * 12 +
                    ((baseResults.modelRevenue + eliteRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        eliteLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        eliteLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            eliteRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        unlimitedYearOne:
            (baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced + 0 + 0 / wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    0 * (costToProduce / 5))) /
                (baseResults.lbsProduced + 0 + 0 / wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                0 +
                ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    0 +
                    ((baseResults.modelRevenue + 0) * 0.01) / wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        0 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        0 +
                        ((baseResults.modelRevenue + 0) * 0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease) * 0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation +
                        ((baseResults.modelRevenue + unlimitedRevenueIncrease) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 2 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 2) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 2 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 2) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 2 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 2 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 2) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 3 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 3) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 3 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 3) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 3 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 3 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 3) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 4 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 4) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 4 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 4) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 4 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 4 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 4) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 5 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 5) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 5 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 5) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 5 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 5 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 5) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 6 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 6) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 6 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 6) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 6 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 6 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 6) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 7 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 7) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 7 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 7) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 7 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 7 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 7) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 8 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 8) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 8 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 8) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 8 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 8 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 8) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 9 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 9) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 9 +
                    ((baseResults.modelRevenue + unlimitedRevenueIncrease * 9) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 9 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 9 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 9) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 10 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 10) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 10 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 10) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 10 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 10 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 10) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        unlimitedYearTwo:
            (baseResults.lbsProduced +
                unlimitedLbsCultivation * 11 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 11) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 11 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 11) *
                    0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    unlimitedLbsCultivation * 11 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    unlimitedLbsCultivation * 11 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 11) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
        unlimitedYearThree:
            (baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                (baseResults.lbsProduced * costToProduce +
                    unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                (baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) -
            baseResults.profit +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit) +
            ((baseResults.lbsProduced +
                unlimitedLbsCultivation * 12 +
                ((baseResults.modelRevenue + unlimitedRevenueIncrease * 12) *
                    0.01) /
                    wholesalePrice) *
                wholesalePrice -
                ((baseResults.lbsProduced +
                    unlimitedLbsCultivation * 12 +
                    ((baseResults.modelRevenue +
                        unlimitedRevenueIncrease * 12) *
                        0.01) /
                        wholesalePrice) *
                    (baseResults.lbsProduced * costToProduce +
                        unlimitedLbsCultivation * 12 * (costToProduce / 5))) /
                    (baseResults.lbsProduced +
                        unlimitedLbsCultivation * 12 +
                        ((baseResults.modelRevenue +
                            unlimitedRevenueIncrease * 12) *
                            0.01) /
                            wholesalePrice) -
                baseResults.profit),
    };

    return (
        <Document>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <View style={styles.frame}>
                    <View style={styles.intro}>
                        <Text style={styles.introTitle}>AROYA {plan}</Text>
                    </View>
                    <View style={styles.inputsTable}>
                        <Text style={styles.tableHeading}>Your Facility</Text>
                        <View style={styles.tableRow}>
                            <Text style={styles.inputsTitle}>
                                Square feet of canopy
                            </Text>
                            <Text style={styles.inputsData}>{sqFtCanopy}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.inputsTitle}>
                                Current g / sq ft / harvest
                            </Text>
                            <Text style={styles.inputsData}>
                                {gSqFtHarvest}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.inputsTitle}>
                                State wholesale price
                            </Text>
                            <Text style={styles.inputsData}>
                                {formatter.format(wholesalePrice)}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.inputsTitle}>
                                Cost to produce (lb)
                            </Text>
                            <Text style={styles.inputsData}>
                                {formatter.format(costToProduce)}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.inputsTitle}>
                                Harvests per year
                            </Text>
                            <Text style={styles.inputsData}>
                                {harvestsPerYr}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.inputsTitle}>
                                Propagation rooms
                            </Text>
                            <Text style={styles.inputsData}>{propRooms}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.inputsTitle}>Veg rooms</Text>
                            <Text style={styles.inputsData}>{vegRooms}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.inputsTitle}>Flower rooms</Text>
                            <Text style={styles.inputsData}>{flowerRooms}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.inputsTitle}>Drying rooms</Text>
                            <Text style={styles.inputsData}>{dryingRooms}</Text>
                        </View>
                    </View>
                    <View style={styles.outputTable}>
                        <Text style={styles.tableHeading}>{plan} Plan</Text>
                        <View style={styles.tableRow}>
                            <Text style={styles.outputTitle}>
                                Yield increases start in
                            </Text>
                            <Text style={styles.outputData}>
                                {plan === 'GO' && '6 months'}
                                {plan === 'PRO' && '5 months'}
                                {plan === 'Elite' && '4 months'}
                                {plan === 'Unlimited' && '3 months'}
                            </Text>
                        </View>
                        {/* <View style={styles.tableRow}>
                            <Text style={styles.outputTitle}>
                                Yield increase each month
                            </Text>
                            <Text style={styles.outputData}>
                                {plan === 'GO' && `${goLbsCultivation} lbs`}
                                {plan === 'PRO' && `${proLbsCultivation} lbs`}
                                {plan === 'Elite' &&
                                    `${eliteLbsCultivation} lbs`}
                                {plan === 'Unlimited' &&
                                    `${unlimitedLbsCultivation} lbs`}
                            </Text>
                        </View> */}
                        <View style={styles.tableRow}>
                            <Text style={styles.outputTitle}>
                                Profit increase year 1
                            </Text>
                            <Text style={styles.outputData}>
                                {plan === 'GO' && `Contact Sales`}
                                {plan === 'PRO' &&
                                    `${formatter.format(
                                        Math.round(
                                            profits.proYearOne -
                                                proSoftwareFee -
                                                Math.round(
                                                    proTotalHardwareCost * 0.2
                                                ) -
                                                Math.round(
                                                    computeMonthLease(
                                                        proTotalHardwareCost,
                                                        Math.round(
                                                            proTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                ) *
                                                    12 <=
                                                0
                                                ? 0
                                                : profits.proYearOne -
                                                      proSoftwareFee -
                                                      Math.round(
                                                          proTotalHardwareCost *
                                                              0.2
                                                      ) -
                                                      Math.round(
                                                          computeMonthLease(
                                                              proTotalHardwareCost,
                                                              Math.round(
                                                                  proTotalHardwareCost *
                                                                      0.2
                                                              )
                                                          )
                                                      ) *
                                                          12
                                        )
                                    )}`}
                                {plan === 'Elite' &&
                                    `${formatter.format(
                                        Math.round(
                                            profits.eliteYearOne -
                                                eliteSubscriptionCost -
                                                Math.round(
                                                    eliteTotalHardwareCost * 0.2
                                                ) -
                                                Math.round(
                                                    computeMonthLease(
                                                        eliteTotalHardwareCost,
                                                        Math.round(
                                                            eliteTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                ) *
                                                    12 <=
                                                0
                                                ? 0
                                                : profits.eliteYearOne -
                                                      eliteSubscriptionCost -
                                                      Math.round(
                                                          eliteTotalHardwareCost *
                                                              0.2
                                                      ) -
                                                      Math.round(
                                                          computeMonthLease(
                                                              eliteTotalHardwareCost,
                                                              Math.round(
                                                                  eliteTotalHardwareCost *
                                                                      0.2
                                                              )
                                                          )
                                                      ) *
                                                          12
                                        )
                                    )}`}
                                {plan === 'Unlimited' &&
                                    `${formatter.format(
                                        Math.round(
                                            profits.unlimitedYearOne -
                                                unlimitedSubscriptionCost -
                                                Math.round(
                                                    unlimitedTotalHardwareCost *
                                                        0.2
                                                ) -
                                                Math.round(
                                                    computeMonthLease(
                                                        unlimitedTotalHardwareCost,
                                                        Math.round(
                                                            unlimitedTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                ) *
                                                    12 <=
                                                0
                                                ? 0
                                                : profits.unlimitedYearOne -
                                                      unlimitedSubscriptionCost -
                                                      Math.round(
                                                          unlimitedTotalHardwareCost *
                                                              0.2
                                                      ) -
                                                      Math.round(
                                                          computeMonthLease(
                                                              unlimitedTotalHardwareCost,
                                                              Math.round(
                                                                  unlimitedTotalHardwareCost *
                                                                      0.2
                                                              )
                                                          )
                                                      ) *
                                                          12
                                        )
                                    )}`}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.outputTitle}>
                                Profit increase year 2
                            </Text>
                            <Text style={styles.outputData}>
                                {plan === 'GO' && `Contact Sales`}
                                {plan === 'PRO' &&
                                    `${formatter.format(
                                        Math.round(
                                            profits.proYearTwo -
                                                proSoftwareFee -
                                                Math.round(
                                                    computeMonthLease(
                                                        proTotalHardwareCost,
                                                        Math.round(
                                                            proTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                ) *
                                                    12 <=
                                                0
                                                ? 0
                                                : profits.proYearTwo -
                                                      proSoftwareFee -
                                                      Math.round(
                                                          computeMonthLease(
                                                              proTotalHardwareCost,
                                                              Math.round(
                                                                  proTotalHardwareCost *
                                                                      0.2
                                                              )
                                                          )
                                                      ) *
                                                          12
                                        )
                                    )}`}
                                {plan === 'Elite' &&
                                    `${formatter.format(
                                        Math.round(
                                            profits.eliteYearTwo -
                                                eliteSubscriptionCost -
                                                Math.round(
                                                    computeMonthLease(
                                                        eliteTotalHardwareCost,
                                                        Math.round(
                                                            eliteTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                ) *
                                                    12 <=
                                                0
                                                ? 0
                                                : profits.eliteYearTwo -
                                                      eliteSubscriptionCost -
                                                      Math.round(
                                                          computeMonthLease(
                                                              eliteTotalHardwareCost,
                                                              Math.round(
                                                                  eliteTotalHardwareCost *
                                                                      0.2
                                                              )
                                                          )
                                                      ) *
                                                          12
                                        )
                                    )}`}
                                {plan === 'Unlimited' &&
                                    `${formatter.format(
                                        Math.round(
                                            profits.unlimitedYearTwo -
                                                unlimitedSubscriptionCost -
                                                Math.round(
                                                    computeMonthLease(
                                                        unlimitedTotalHardwareCost,
                                                        Math.round(
                                                            unlimitedTotalHardwareCost *
                                                                0.2
                                                        )
                                                    )
                                                ) *
                                                    12 <=
                                                0
                                                ? 0
                                                : profits.unlimitedYearTwo -
                                                      unlimitedSubscriptionCost -
                                                      Math.round(
                                                          computeMonthLease(
                                                              unlimitedTotalHardwareCost,
                                                              Math.round(
                                                                  unlimitedTotalHardwareCost *
                                                                      0.2
                                                              )
                                                          )
                                                      ) *
                                                          12
                                        )
                                    )}`}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.outputTitle}>
                                Year 1 down payment
                            </Text>
                            <Text style={styles.outputData}>
                                {plan === 'GO' && `*Call for pricing`}
                                {plan === 'PRO' &&
                                    `${formatter.format(
                                        Math.round(proTotalHardwareCost * 0.2)
                                    )}`}
                                {plan === 'Elite' &&
                                    `${formatter.format(
                                        Math.round(eliteTotalHardwareCost * 0.2)
                                    )}`}
                                {plan === 'Unlimited' &&
                                    `${formatter.format(
                                        Math.round(
                                            unlimitedTotalHardwareCost * 0.2
                                        )
                                    )}`}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.outputTitle}>
                                Monthly hardware lease
                            </Text>
                            <Text style={styles.outputData}>
                                {plan === 'GO' && `*Call for pricing`}
                                {plan === 'PRO' &&
                                    `${formatter.format(
                                        Math.round(
                                            computeMonthLease(
                                                proTotalHardwareCost,
                                                Math.round(
                                                    proTotalHardwareCost * 0.2
                                                )
                                            )
                                        )
                                    )}`}
                                {plan === 'Elite' &&
                                    `${formatter.format(
                                        Math.round(
                                            computeMonthLease(
                                                eliteTotalHardwareCost,
                                                Math.round(
                                                    eliteTotalHardwareCost * 0.2
                                                )
                                            )
                                        )
                                    )}`}
                                {plan === 'Unlimited' &&
                                    `${formatter.format(
                                        Math.round(
                                            computeMonthLease(
                                                unlimitedTotalHardwareCost,
                                                Math.round(
                                                    unlimitedTotalHardwareCost *
                                                        0.2
                                                )
                                            )
                                        )
                                    )}`}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.outputTitle}>
                                Yearly subscription
                            </Text>
                            <Text style={styles.outputData}>
                                {plan === 'GO' && `$0`}
                                {plan === 'PRO' &&
                                    `${formatter.format(proSoftwareFee)}`}
                                {plan === 'Elite' &&
                                    `${formatter.format(
                                        eliteSubscriptionCost
                                    )}`}
                                {plan === 'Unlimited' &&
                                    `${formatter.format(
                                        unlimitedSubscriptionCost
                                    )}`}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.featuresTable}>
                        <Text style={styles.tableHeading}>Plan Features</Text>
                        <View style={styles.tableRow}>
                            <Text style={styles.featuresTitle}>Users</Text>
                            <Text
                                style={
                                    plan === 'GO'
                                        ? styles.featuresDataNo
                                        : styles.featuresData
                                }
                            >
                                {plan === 'GO' && 'No'}
                                {plan === 'PRO' && 'Unlimited'}
                                {plan === 'Elite' && 'Unlimited'}
                                {plan === 'Unlimited' && 'Unlimited'}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.featuresTitle}>
                                METRC Integration
                            </Text>
                            <Text
                                style={
                                    plan === 'GO' || plan === 'PRO'
                                        ? styles.featuresDataNo
                                        : styles.featuresData
                                }
                            >
                                {plan === 'GO' && 'No'}
                                {plan === 'PRO' && 'No'}
                                {plan === 'Elite' && 'Yes'}
                                {plan === 'Unlimited' && 'Yes'}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.featuresTitle}>Analytics</Text>
                            <Text
                                style={
                                    plan === 'GO' || plan === 'PRO'
                                        ? styles.featuresDataNo
                                        : styles.featuresData
                                }
                            >
                                {plan === 'GO' && 'No'}
                                {plan === 'PRO' && 'No'}
                                {plan === 'Elite' && 'Yes'}
                                {plan === 'Unlimited' && 'Yes'}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.featuresTitle}>
                                Customer success calls
                            </Text>
                            <Text
                                style={
                                    plan === 'GO' || plan === 'PRO'
                                        ? styles.featuresDataNo
                                        : styles.featuresData
                                }
                            >
                                {plan === 'GO' && 'No'}
                                {plan === 'PRO' && 'No'}
                                {plan === 'Elite' && 'Monthly'}
                                {plan === 'Unlimited' && 'Weekly'}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.featuresTitle}>
                                Guaranteed Yield Increase*
                            </Text>
                            <Text
                                style={
                                    plan === 'GO' ||
                                    plan === 'PRO' ||
                                    plan === 'Elite'
                                        ? styles.featuresDataNo
                                        : styles.featuresData
                                }
                            >
                                {plan === 'GO' && 'No'}
                                {plan === 'PRO' && 'No'}
                                {plan === 'Elite' && 'No'}
                                {plan === 'Unlimited' && '10%'}
                            </Text>
                        </View>
                        {/* <View style={styles.tableRow}>
                            <Text style={styles.featuresTitle}>
                                Cost per sensor
                            </Text>
                            <Text style={styles.featuresData}>???</Text>
                        </View> */}
                    </View>
                    <View style={styles.footer}>
                        <Text style={styles.footerText}>
                            *For detailed pricing, please call{' '}
                            <a href="tel:+1.509.332.2756">+1.509.332.2756</a>
                        </Text>
                    </View>
                </View>
                <Svg
                    width="50"
                    height="50"
                    viewBox="0 0 276 237"
                    fill="none"
                    style={styles.logo}
                >
                    <Path
                        d="M276 18.7256C276 28.9116 268.078 36.8341 257.891 36.8341C247.705 36.8341 239.5 28.9116 239.5 18.7256C239.5 8.82249 247.705 0.900002 257.891 0.900002C268.078 1.18295 276 9.10543 276 18.7256ZM244.027 18.7256C244.027 26.6481 249.969 32.8729 257.891 32.8729C265.814 32.8729 271.473 26.6481 271.473 18.7256C271.473 10.8031 265.814 4.29535 257.891 4.29535C249.969 4.5783 244.027 11.0861 244.027 18.7256ZM255.062 28.0628H250.818V10.2372C252.516 9.95427 254.779 9.67132 257.609 9.67132C261.004 9.67132 262.419 10.2372 263.833 11.0861C264.682 11.9349 265.531 13.3496 265.531 15.0473C265.531 17.0279 264.116 18.4426 261.853 19.2915V19.5744C263.55 20.1403 264.682 21.5551 264.965 23.8186C265.531 26.6481 265.814 27.4969 266.38 28.3458H261.853C261.287 27.7799 261.004 26.0822 260.438 24.1016C260.155 22.1209 259.023 21.2721 256.76 21.2721H254.779V28.0628H255.062ZM255.062 18.1597H257.043C259.306 18.1597 261.287 17.3109 261.287 15.6132C261.287 13.9155 260.155 12.7837 257.609 12.7837C256.477 12.7837 255.628 12.7837 255.345 13.0667V18.1597H255.062Z"
                        fill="black"
                    ></Path>
                    <Path
                        d="M87.4 104.7H77.9V116.6H87.5C91.6 116.6 93.9 114.5 93.9 110.7C94 107.5 91.5 104.7 87.4 104.7Z"
                        fill="black"
                    ></Path>
                    <Path
                        d="M42.4 124H53.7L48 107.2L42.4 124Z"
                        fill="black"
                    ></Path>
                    <Path
                        d="M121.8 104.2C119.5 104.2 117.1 105.2 115.5 106.8C112.8 109.7 112.6 114.6 112.6 118.2C112.6 121.8 112.7 126.7 115.5 129.6C117.1 131.3 119.5 132.2 121.8 132.2C124.1 132.2 126.5 131.2 128.1 129.6C130.8 126.7 131 121.9 131 118.2C131 114.6 130.9 109.6 128.1 106.8C126.5 105.2 124.1 104.2 121.8 104.2Z"
                        fill="black"
                    ></Path>
                    <Path
                        d="M182.6 124H193.9L188.2 107.2L182.6 124Z"
                        fill="black"
                    ></Path>
                    <Path
                        d="M0 0V236.5H236.5V0H0ZM58.5 138.1L55.9 130.5H40.1L37.5 138.1H30.3L44.2 98.4H52.1L66 138.1H58.5ZM101.2 138.1H93.4L86.8 123.1H78V138.1H70.7V98.4H87.4C96.9 98.4 101.2 104.3 101.2 110.7C101.2 116 98.3 120.2 93.9 121.8L101.2 138.1ZM133.2 134.3C130.2 137.3 126 138.8 121.8 138.8C117.6 138.8 113.4 137.3 110.4 134.3C106.1 130 105.4 124.7 105.4 118.2C105.4 111.7 106.1 106.4 110.4 102.1C113.4 99.1 117.6 97.6 121.8 97.6C126.1 97.6 130.2 99.1 133.2 102.1C137.5 106.4 138.2 111.7 138.2 118.2C138.2 124.8 137.5 130 133.2 134.3ZM159.7 138.1H152.4V122.9L138.6 98.4H146.4L156.2 115.5L166 98.4H173.5L159.6 122.9V138.1H159.7ZM198.7 138.1L196.1 130.5H180.3L177.7 138.1H170.4L184.3 98.4H192.2L206.1 138.1H198.7Z"
                        fill="black"
                    ></Path>
                </Svg>
            </Page>
        </Document>
    );
};

export default PdfDocPlan;
